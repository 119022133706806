import React from "react";

import { ContainerTeacher, ImageTeacher } from "./styles";

interface IProps {
	image: string;
}

const Teacher = ({ image }: IProps) => {
	return (
		<ContainerTeacher>
			<ImageTeacher src={image} />
		</ContainerTeacher>
	);
};

export default Teacher;
