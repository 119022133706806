import styled from "styled-components";

import { MediaQueries } from "../../../../../assets/mediaQueries";

interface IStyledProps {
	left?: boolean;
}

export const Arrow = styled.div<IStyledProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	margin-top: -30px;
	right: ${({ left }) => (left ? "none" : "-10px")};
	left: ${({ left }) => (left ? "-30px" : "none")};
	cursor: pointer;

	${({ left }) =>
		!left &&
		`
	img {
		transform: rotate(-180deg);
	}`}

	${MediaQueries.SMALLER_THAN_WIDE} {
		left: ${({ left }) => (left ? "0px" : "none")};
		right: ${({ left }) => (left ? "none" : "0px")};

		z-index: 5;
	}
`;
