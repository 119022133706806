import React from "react";
// import { MediaQueries } from "../../../assets/mediaQueries";
import styled from "styled-components";

export const AulaoLegendWrapp = styled.p`
	font-size: 16px;
	font-weight: normal;
	line-height: 1.6;
	font-family: "Nunito Sans";
	margin: 20px 0;
`;

const AulaoLegend = ({ children }: { children: any }) => (
	<AulaoLegendWrapp>{children}</AulaoLegendWrapp>
);

export default AulaoLegend;
