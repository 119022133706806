import { BreakPoints } from "../assets/mediaQueries";
import { useEffect, useState } from "react";

const useIsMobile = (breakpoint = BreakPoints.small) => {
	const [isMobile, setIsMobile] = useState(false);
	const matchMedia = window.matchMedia(`(max-width: ${breakpoint - 1}px)`);
	const checkWidth = (e: MediaQueryListEvent) => setIsMobile(e.matches);

	useEffect(() => {
		setIsMobile(matchMedia.matches);
		// tslint:disable-next-line: deprecation
		matchMedia.addListener(checkWidth);

		return () => {
			// tslint:disable-next-line: deprecation
			matchMedia.removeListener(checkWidth);
		};
	}, [matchMedia]);

	return isMobile;
};

export default useIsMobile;
