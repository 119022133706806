import styled from "styled-components";
import { Section } from "../styles";
import Container from "../../container";
import { MediaQueries } from "../../../assets/mediaQueries";
import BG from "./img/rolanndo-bg.png";

export const BgVideo = styled.img`
	display: none;

	${MediaQueries.BIGGER_THAN_SMALL} {
		display: block;
		position: absolute;
		left: -20px;
		top: 50px;
		width: 100%;
		max-width: 430px;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		max-width: 600px;
		top: -20px;
	}
`;

export const TaRolandoContainer = styled(Section)`
	display: flex;
	flex-direction: column;

	${Container} {
		position: relative;
		z-index: 2;
	}

	${MediaQueries.BIGGER_THAN_SMALL} {
		${Container} {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		margin-top: 40px;
	}
`;

export const TaRolandoTop = styled.div`
	background: #fdcb76;
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
	position: relative;
	padding: 50px 0;
`;

export const TaRolandoBottom = styled.div`
	background: url(${BG}) center bottom no-repeat;
	background-size: cover;
	height: 177px;
	margin-top: -2px;
	width: 100%;

	${MediaQueries.SMALL} {
		height: 57px;
	}

	${MediaQueries.TABLET_PORTRAIT} {
		height: 57px;
	}
`;

const Vector = styled.figure`
	left: -41px;
	position: absolute;
	top: -41px;
	z-index: 1;
`;

export const Vector1 = styled(Vector)`
	height: 266px;
	width: 266px;

	img {
		height: 100%;
		width: 100%;
	}

	${MediaQueries.SMALL} {
		transform: scale(0.6);
	}

	${MediaQueries.TABLET_PORTRAIT} {
		transform: scale(0.6);
	}
`;

export const Vector2 = styled.figure`
	background: #f01d4a;
	border-radius: 32px;
	transform: rotate(72deg) scale(0.6) scaleY(-1);
	position: absolute;
	right: 0px;
	top: -55px;
	width: 138px;
	height: 138px;
	z-index: 1;

	&:after {
		background: transparent;
		border: 4.75px solid #571d5d;
		border-radius: 32px;
		content: "";
		left: 50px;
		position: absolute;
		height: calc(138px - 9.5px);
		top: -63px;
		width: calc(138px - 9.5px);
	}
`;

const Circle = styled.figure`
	border-radius: 50%;
	bottom: 0;
	height: 193px;
	position: absolute;
	width: 193px;
`;

export const Vector3 = styled(Circle)`
	background: #f01d4a;
	bottom: -180px;
	right: 550px;
	z-index: 2;

	&:after {
		background: transparent;
		border: 2.69px solid #fddea4;
		border-radius: 50%;
		content: "";
		left: -20px;
		position: absolute;
		height: 100%;
		top: -32px;
		width: 100%;
	}

	${MediaQueries.SMALL} {
		transform: scale(0.6);
	}

	${MediaQueries.TABLET_PORTRAIT} {
		transform: scale(0.6);
	}
`;

export const Button = styled.a`
	display: block;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #571d5d;
	border: 1.5px solid #571d5d;
	border-radius: 4px;
	padding: 10px 25px;
	text-decoration: none;
	max-width: 232px;
	margin: 0 auto;
	margin-bottom: 30px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-bottom: 0px;
		display: inline-block;
	}
`;

export const TaRolandoVideo = styled.div`
	position: relative;
	margin-left: 20px;
	margin-right: 20px;

	img {
		display: none;
	}

	${MediaQueries.BIGGER_THAN_SMALL} {
		img {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 200%;
			z-index: 1;
			/* max-width: 200px; */
		}
	}

	> div {
		width: 100% !important;
		margin: 0 auto;
		background: #fff;
		padding: 8px;
		border-radius: 4px;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	}
`;

export const TaRolandoTitle = styled.h2`
	color: #df1d3d;
	font-family: "Nunito";
	font-size: 42px;
	font-weight: 700;
	margin-bottom: 15px;
	line-height: 1;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}
`;

export const TaRolandoSubtitle = styled.h2`
	color: #4b1451;
	font-family: "Nunito";
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 15px;
	line-height: 1;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}
`;

export const Text = styled.p`
	color: #505462;
	font-size: 16px;
	margin-top: 50px;
	margin-bottom: 30px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
		padding-left: 0px;
		margin-top: 20px;
	}
`;
