import styled from "styled-components";
import { MediaQueries } from "../../assets/mediaQueries";

export default styled.div`
	margin: 0 auto;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		max-width: 960px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		max-width: 1200px;
	}
`;
