import styled from "styled-components";
import { MediaQueries } from "../../../assets/mediaQueries";

import bg1 from "../../../assets/img/raiox-2/bg-1.png";
import bg2 from "../../../assets/img/raiox-2/bg-2.png";
import {
	Logo as OriginalLogo,
	Section as OriginalSection,
	Title as OriginalTitle,
	Text as OriginalText
} from "../raiox/styles";

export const Section = styled(OriginalSection)`
	background-color: #fdcb76;
	margin-top: 0px;
	padding-top: 40px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		background-image: url(${bg1}), url(${bg2});
		background-position: left center, right center;
		background-repeat: no-repeat, no-repeat;
		background-size: 170%, 0%;

		min-height: 100vh !important;
		display: flex;
		align-items: center;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		padding-bottom: 40px;
	}
`;

export const Logo = styled(OriginalLogo)``;

export const Title = styled(OriginalTitle)`
	color: #25272d;
`;

export const Text = styled(OriginalText)`
	color: #505462;
`;
