import React from "react";

// Components

// Assets
import Logo from "../../../assets/images/AULAO-PRESENCIAL-LOGO-02.png";
import Cubes from "../../../assets/images/aulao-presencial-cubes.png";
import Bubble from "../../../assets/images/aulao-presencial-bubble.png";
import BubblePicture from "../../../assets/images/aulao-presencial.png";
// import Group from "../../../assets/images/aula-presencial-group.png";

import {
	AulaoPresencialContainer,
	AulaoPresencialLogo,
	AulaoPresencialCubes,
	AulaoPresencialContent,
	AulaoPresencialHeader,
	AulaoPresencialTexts,
	AulaoPresencialBubble,
	AulaoPresencialBubbleImage,
	AulaoPresencialBubbleImagePicture,
	AulaoPresencialBubbleYear
} from "./styles";

// Helpers
import { Row, Col } from "../../grid";
import AulaoLegend from "../../aulao/legend";
import AulaoTitle from "../../aulao/title";
import AulaoButton from "../../aulao/button";
import Container from "../../container";
import useIsMobile from "../../../hooks/use-is-mobile";

const AulaoPresencial = () => {
	const isMobile = useIsMobile();
	return (
		<AulaoPresencialContainer>
			<AulaoPresencialHeader>
				<Container>
					<AulaoPresencialCubes>
						<img src={Cubes} alt="Aulao Presencial Cubes" />
					</AulaoPresencialCubes>
					<AulaoPresencialLogo>
						<img src={Logo} alt="Aulão Presencial" />
					</AulaoPresencialLogo>
				</Container>
			</AulaoPresencialHeader>
			<AulaoPresencialContent>
				<Container>
					<Row middle="lg">
						<Col sm={6} xs={12}>
							<AulaoPresencialTexts>
								<AulaoTitle>
									Quer conhecer nosso time pessoalmente?
								</AulaoTitle>
								{isMobile && (
									<AulaoLegend>
										No <strong>dia 27/10</strong> temos um
										encontro na sede do Proenem!
										<br />
										<br />É o momento para revisar alguns
										dos conteúdos mais importantes e ver de
										perto aqueles que passaram o ano inteiro
										compartilhando conhecimento com você.
										<br />
										<br />
										Você também terá a chance de conhecer os
										nossos estúdios e participar de um
										encontro especial com os nossos
										professores. Vai ser irado!
									</AulaoLegend>
								)}
								{!isMobile && (
									<AulaoLegend>
										No <strong>dia 27/10</strong> temos um
										encontro na sede do Proenem! É o momento
										para revisar alguns dos conteúdos mais
										importantes e ver de perto aqueles que
										passaram o ano inteiro compartilhando
										conhecimento com você. Você também terá
										a chance de conhecer os nossos estúdios
										e participar de um encontro especial com
										os nossos professores. Vai ser irado!
									</AulaoLegend>
								)}
								<div>
									<AulaoButton onClick={() => null}>
										Saiba mais
									</AulaoButton>
								</div>
							</AulaoPresencialTexts>
						</Col>
						<Col sm={5} xs={12}>
							<AulaoPresencialBubble>
								<AulaoPresencialBubbleImagePicture>
									<AulaoPresencialBubbleYear>
										Aulão 2018
									</AulaoPresencialBubbleYear>
									<img
										src={BubblePicture}
										alt="Último Aulão presencial do ProEnem!"
									/>
								</AulaoPresencialBubbleImagePicture>
								<AulaoPresencialBubbleImage
									src={Bubble}
									alt="Último Aulão presencial do ProEnem!"
								/>
							</AulaoPresencialBubble>
						</Col>
					</Row>
				</Container>
			</AulaoPresencialContent>
		</AulaoPresencialContainer>
	);
};

export default AulaoPresencial;
