import React from "react";
import styled from "styled-components";

export const AulaoTitleWrapp = styled.h2`
	font-size: 22px;
	font-weight: 800;
	line-height: 1.1;
	font-family: Nunito;
`;

const AulaoTitle = ({ children }: { children: any }) => (
	<AulaoTitleWrapp>{children}</AulaoTitleWrapp>
);

export default AulaoTitle;
