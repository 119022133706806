import React from "react";

// Components
import Battle from "./battle";

// Assets
import Container from "../../container";
import Arrow from "./img/arrow.png";
import GameShowLogo from "./img/game-show-logo.png";
import {
	GameShowContainer,
	GameShowTitle,
	GameShowLogoContainer,
	GameShowText,
	GameShowLogoArrow,
	Guest
} from "./styles";
import { Row, Col } from "../../grid";
import useIsMobile from "../../../hooks/use-is-mobile";
// import Thati from "./img/thati-nome.png";

const GameShow = () => {
	const isMobile = useIsMobile();
	return (
		<GameShowContainer>
			<Container>
				<Row
					style={{
						flexDirection: isMobile ? "column-reverse" : undefined
					}}
					middle="sm"
				>
					<Col xs={12} sm={6}>
						<GameShowTitle>
							Humanas vs. Exatas <br />
							Quem vai levar a melhor?
						</GameShowTitle>
						<GameShowText>
							Um supergame de perguntas e respostas com conteúdo
							relacionado ao Enem. Você aprende enquanto se
							diverte com os nossos professores. O vencedor leva
							um prêmio sensacional. Os perdedores… Só assistindo
							para saber. Você também poderá participar e ganhar
							prêmios exclusivos. E aí, pronto para o jogo?
						</GameShowText>
					</Col>
					<Col xs={12} sm={5} md={6}>
						<GameShowLogoContainer
							alt="Game Show"
							src={GameShowLogo}
						/>
						<GameShowLogoArrow
							alt="Quem vai levar a melhor?"
							src={Arrow}
						/>
					</Col>
				</Row>
				<Battle />
			</Container>

			{/* <Guest alt="Thati Lopes" src={Thati} /> */}
		</GameShowContainer>
	);
};

export default GameShow;
