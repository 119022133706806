import React, { useState } from "react";

import { Row, Col } from "../../grid";
import {
	WrapperInformationsGuests,
	LogoGuests,
	TitleGuests,
	InformationsGuests,
	ButtonGuests,
	GuestsSection,
	ContainerSliderGuest,
	SliderGuests
} from "./styles";

import teacherMarcusEnnes from "../../../assets/images/guests/slide/teachers-02.png";
import teacherFerreto from "../../../assets/images/guests/slide/teachers-03.png";
import teacherNoslen from "../../../assets/images/guests/slide/teachers-04.novo.png";
import teacherPauloPereira from "../../../assets/images/guests/slide/teachers-05.png";
import teacherFilipeFigueiredo from "../../../assets/images/guests/slide/teachers-06.png";
import teacherLuizAntonioSimas from "../../../assets/images/guests/slide/teachers-07.png";

import Teacher from "./teacher";
import BalloonTeacher from "./balloon";
import Container from "../../container";
import Arrows from "./arrows";
import useIsMobile from "../../../hooks/use-is-mobile";
import { BreakPoints } from "../../../assets/mediaQueries";
import { useStateContext } from "../../../contexts/state";

const guests = [
	{
		img: teacherLuizAntonioSimas,
		day: "Dia 07/10",
		teacher: "Luiz Antônio Simas",
		description: (
			<>
				Historiador
				<br />e Escritor
			</>
		)
	},
	{
		img: teacherFilipeFigueiredo,
		day: "Dia 08/10",
		teacher: "Filipe Figueiredo",
		description: "Xadrez Verbal"
	},
	{
		img: teacherMarcusEnnes,
		day: "Dia 09/10",
		teacher: "Marcus Ennes",
		description: "Canal Química do Monstro"
	},
	{
		img: teacherPauloPereira,
		day: "Dia 09/10",
		teacher: "Paulo Pereira",
		description: "Canal Equaciona Matemática"
	},
	{
		img: teacherNoslen,
		day: "Dia 10/10",
		teacher: "Noslen",
		description: (
			<>
				Canal Professor
				<br />
				Noslen
			</>
		)
	},
	{
		img: teacherFerreto,
		day: "Dia 11/10",
		teacher: "Ferretto",
		description: "Canal Ferreto Matemática"
	}
];

const Guests = () => {
	const { dispatch } = useStateContext();
	const [currentTeacher, setCurrentTeacher] = useState(0);

	const isMobile = useIsMobile(BreakPoints.medium);

	const handleCurrentTeacher = (value: number) => {
		const currentValue = value <= 0 ? 0 : value;
		const position =
			currentValue >= guests.length ? guests.length - 1 : currentValue;

		setCurrentTeacher(position);
	};

	const renderGuests = () => {
		const mappedGuests = guests.map(guest => (
			<Teacher key={guest.teacher} image={guest.img} />
		));

		return mappedGuests;
	};

	const handleButton = () => {
		dispatch({
			type: "setModal",
			payload: { type: "signUp", visible: true }
		});
	};

	return (
		<GuestsSection>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={5}>
						<WrapperInformationsGuests>
							<LogoGuests />
							<TitleGuests>
								Nossos professores em aulas com convidados
								sensacionais
							</TitleGuests>
							<InformationsGuests>
								Na semana de <strong>7 a 11 de outubro</strong>,
								teremos vários convidados especiais dando aulas
								incríveis com o nosso time de mestres. Este ano
								além das aulas com os convidados, nossos
								professores também darão aulas em dupla. Bora
								estudar?
							</InformationsGuests>
							<ButtonGuests onClick={handleButton}>
								Me avisa aí
							</ButtonGuests>
						</WrapperInformationsGuests>
					</Col>
					<Col xs={12} sm={4} md={5} smOffset={1} mdOffset={2}>
						<ContainerSliderGuest>
							<SliderGuests
								infinite={false}
								arrows
								dots={!isMobile}
								beforeChange={(oldIndex, newIndex) => {
									handleCurrentTeacher(newIndex);
								}}
								slidesToShow={1}
								slidesToScroll={1}
								nextArrow={<Arrows />}
								prevArrow={<Arrows left />}
							>
								{renderGuests()}
							</SliderGuests>
							<BalloonTeacher
								day={guests[currentTeacher].day}
								teacher={guests[currentTeacher].teacher}
								description={guests[currentTeacher].description}
							/>
						</ContainerSliderGuest>
					</Col>
				</Row>
			</Container>
		</GuestsSection>
	);
};

export default Guests;
