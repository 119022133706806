import React, { useState } from "react";
import styled from "styled-components";
import firebase from "firebase";
import InputMask from "react-input-mask";
import Loader from "../loader";
import Container from "../container";

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const Close = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
`;

const Video = styled.div`
	background: #fff;
	padding: 10px;
	width: 100%;

	iframe {
		width: 100%;
		height: 80vh;
	}
`;

const FormContainer = styled(Container)`
	max-width: 380px;

	${Close} {
		max-width: 380px;
		margin: 10px auto;
	}
`;

const Form = styled.form`
	background: #fff;
	padding: 10px;
	border-radius: 4px;
	width: 100%;
	max-width: 378px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	margin: 0 auto;

	h2 {
		font-weight: bold;
		font-size: 20px;
		color: #25272d;
		margin-bottom: 20px;
	}

	p.success {
		font-weight: bold;
		margin-top: 10px;
		color: #28b583;
	}

	label {
		display: flex;
		flex-direction: column;
		width: 100%;
		color: #7c8195;
		font-weight: 600;
		font-size: 14px;

		input {
			border: 1px solid #25272d;
			box-sizing: border-box;
			border-radius: 5px;
			margin-top: 10px;
			margin-bottom: 20px;
			padding: 10px;
		}
	}

	button {
		background: #28b583;
		border-radius: 4px;
		border: none;
		text-transform: uppercase;
		display: inline-block;
		flex-shrink: 0;
		width: 104px;
		height: 40px;
		font-weight: 900;
		cursor: pointer;
		align-self: flex-start;
	}
`;

const CloseSVG = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.9985 1.00146L1 17M16.9985 17L1.00073 1"
			stroke="#FAFAFC"
			stroke-width="2"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const ModalWrapper = ({ isVisible, children, onClose }: any) => {
	return isVisible ? <Wrapper>{children}</Wrapper> : null;
};

const SignUpModal = ({ isVisible, onClose }: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fields, setFields] = useState({
		persistedName: "",
		name: "",
		email: "",
		phone: "",
		success: false
	});

	const handleField = (field: string) => (event: any) => {
		setFields({
			...fields,
			[field]: event.target.value
		});
	};
	const handleSubmit = (event: any) => {
		event.preventDefault();

		setIsLoading(true);

		firebase
			.firestore()
			.collection("leads")
			.doc(fields.email)
			.set(
				{ name: fields.name, email: fields.email, phone: fields.phone },
				{ merge: true }
			)
			.then(() => {
				setIsLoading(false);
				setFields({
					persistedName: fields.name,
					name: "",
					email: "",
					phone: "",
					success: true
				});
			});
	};
	return (
		<ModalWrapper isVisible={isVisible} onClose={onClose}>
			<FormContainer>
				<Close onClick={onClose}>
					<CloseSVG />
				</Close>
				<Form onSubmit={handleSubmit}>
					{isLoading && <Loader />}
					<h2>Preencha abaixo e fique por dentro!</h2>
					<label>
						Seu nome:
						<input
							name="name"
							type="text"
							placeholder="Maria Júlia da Silva"
							value={fields.name}
							onChange={handleField("name")}
							required={true}
						/>
					</label>
					<label>
						E-mail:
						<input
							name="email"
							type="email"
							placeholder="maju@gmail.com"
							value={fields.email}
							onChange={handleField("email")}
							required={true}
						/>
					</label>
					<label>
						Celular:
						<InputMask
							formatChars={{
								"9": "[0-9]",
								"?": "[0-9 ]"
							}}
							mask="(99) 99999-999?"
							maskChar=""
							name="phone"
							type="text"
							placeholder="(021) 9999-9999"
							value={fields.phone}
							onChange={handleField("phone")}
							required={true}
						/>
					</label>
					<button type="submit">Enviar</button>
					{fields.success && (
						<p className="success">
							Parabéns, {fields.persistedName}! Você se cadastrou.
						</p>
					)}
				</Form>
			</FormContainer>
		</ModalWrapper>
	);
};

const VideoModal = ({ isVisible, onClose, embed }: any) => (
	<ModalWrapper isVisible={isVisible} onClose={onClose}>
		<Container>
			<Close onClick={onClose}>
				<CloseSVG />
			</Close>
			<Video dangerouslySetInnerHTML={{ __html: embed }} />
		</Container>
	</ModalWrapper>
);

export default ModalWrapper;

export { SignUpModal, VideoModal };
