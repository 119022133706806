export interface ITheme {
	title: string;
}

export interface ITeacher {
	name: string;
	thumb: string;
}

export interface IContentDay {
	teacher: ITeacher;
	time: string;
	subject: string;
	themes: ITheme[];
}
export interface IDay {
	day: string;
	dayToView: string;
	contents: IContentDay[];
}

export const raioxPrimeiraSemana: IDay[] = [
	{
		day: "2019-10-14",
		dayToView: "Dia 14",
		contents: [
			{
				teacher: {
					name: "Bruno Rinaldi",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Bruno-Rinaldi_RX.png"
				},
				time: "17:30",
				subject: "Física",
				themes: [
					{ title: "Ondas" },
					{ title: "Graficos do movimento" },
					{ title: "Movimentos (uniforme e variado)" },
					{ title: "Hidrostática" },
					{ title: "Energia" }
				]
			},
			{
				teacher: {
					name: "Thiago Feitosa",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Thiago-feitosa_RX.png"
				},
				time: "19:30",
				subject: "Geografia",
				themes: [
					{ title: "Revoluções industriais" },
					{ title: "Industrialização brasileira" },
					{ title: "Globalização" },
					{ title: "Comércio mundial" },
					{ title: "Geopolítica do mundo contemporâneo" }
				]
			}
		]
	},
	{
		day: "2019-10-15",
		dayToView: "Dia 15",
		contents: [
			{
				teacher: {
					name: "Vitor Israel",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Vitor-Israel_RX.png"
				},
				time: "17:30",
				subject: "Matemática",
				themes: [
					{ title: "Análise Combinatória" },
					{ title: "Escalas " }
				]
			},
			{
				teacher: {
					name: "José Jorge",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Jose-Jorge_RX.png"
				},
				time: "19:30",
				subject: "Língua Portuguesa",
				themes: [
					{ title: "Linguagem, língua, fala e norma." },
					{ title: "Variação linguística." },
					{ title: "Uso dos pronomes." },
					{ title: "Semântica verbal. " },
					{ title: "Conjunções." }
				]
			}
		]
	},
	{
		day: "2019-10-16",
		dayToView: "Dia 16",
		contents: [
			{
				teacher: {
					name: "Leandro Vieira",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/lendro-vieira_RX.png"
				},
				time: "17:30",
				subject: "Sociologia",
				themes: [
					{ title: "Movimentos Sociais " },
					{ title: "Cultura e Valores" },
					{ title: "Cultura Material e Imaterial " },
					{ title: "Indivíduo e Sociedade " },
					{ title: "Indústria Cultural" }
				]
			},
			{
				teacher: {
					name: "Química",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Luiz-Claudio_RX.png"
				},
				time: "19:30",
				subject: "Língua Portuguesa",
				themes: [
					{ title: "Ligações Intermoleculares" },
					{ title: "Unidades de Concentração" },
					{ title: "Estequiometria" },
					{ title: "Funções Inorgânicas" },
					{ title: "pouição Ambiental" }
				]
			}
		]
	},
	{
		day: "2019-10-17",
		dayToView: "Dia 17",
		contents: [
			{
				teacher: {
					name: "Diego Viug",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/lendro-vieira_RX.png"
				},
				time: "17:30",
				subject: "Matemática",
				themes: [
					{ title: "Estatística - média, moda e mediana" },
					{ title: "Proporcionalidade - regras de três " },
					{ title: "Função afim" },
					{ title: "Função quadrática" },
					{ title: "Porcentagem" }
				]
			},
			{
				teacher: {
					name: "Felipe Moraes",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Felipe-Moares_RX.png"
				},
				time: "19:30",
				subject: "Interpretação de Texto",
				themes: [
					{ title: "Variação linguística" },
					{ title: "Tipologia textual" },
					{ title: "Funções da linguagem" },
					{ title: "Figuras de linguagem" },
					{ title: "Língua e linguagem" }
				]
			}
		]
	},
	{
		day: "2019-10-18",
		dayToView: "Dia 18",
		contents: [
			{
				teacher: {
					name: "Marcos Alvarenga",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Marcos_Alvarenga_RX.png"
				},
				time: "17:30",
				subject: "Biologia",
				themes: [
					{ title: "Fisiologia Humana" },
					{ title: "Fisiologia celular" },
					{ title: "Bioquímica" },
					{ title: "Divisão Celular" },
					{ title: "Membrana Plasmática" }
				]
			},
			{
				teacher: {
					name: "Artur Neto",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/artur-neto_RX.png"
				},
				time: "19:30",
				subject: "Física",
				themes: [
					{ title: "Eletrodinâmica" },
					{ title: "Calorimetria" },
					{ title: "Óptica" },
					{ title: "Termodinâmica" },
					{ title: "Eletromagnetismo" }
				]
			}
		]
	}
];

export const raioxSegundaSemana: IDay[] = [
	{
		day: "2019-10-21",
		dayToView: "Dia 21",
		contents: [
			{
				teacher: {
					name: "Leandro Vieira",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/leandro-vieira_RX2.png"
				},
				time: "17:30",
				subject: "Filosofia",
				themes: [
					{ title: "Ética e política" },
					{ title: "Liberdade" },
					{ title: "Do senso comum ao pensamento filosófico" },
					{ title: "Democracia" },
					{ title: "Platão e Aristóteles" }
				]
			},
			{
				teacher: {
					name: "Leandro Almeida",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/leandro-Almeida_RX2.png"
				},
				time: "19:30",
				subject: "Geografia",
				themes: [
					{ title: "Meio ambiente " },
					{ title: "Urbanização" },
					{ title: "Espaço Agrário" },
					{ title: "Demografia (População e Migrações)" },
					{ title: "Energia" }
				]
			}
		]
	},
	{
		day: "2019-10-22",
		dayToView: "Dia 22",
		contents: [
			{
				teacher: {
					name: "Vitor Israel",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Vitor-Israel_RX.png"
				},
				time: "17:30",
				subject: "Matemática",
				themes: [
					{ title: "Áreas das principais figuras planas" },
					{ title: "Prismas (cubos e paralelepípedos)" },
					{ title: "Trigonometria no triangulo retângulo" }
				]
			},
			{
				teacher: {
					name: "Otto Barreto",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/Otto-Barreto_RX2.png"
				},
				time: "19:30",
				subject: "História",
				themes: [
					{ title: "Expansão Marítima" },
					{ title: "Idade Média" },
					{ title: "Entre Guerras" },
					{ title: "Antiguidade" },
					{ title: "Guerra Fria" }
				]
			}
		]
	},
	{
		day: "2019-10-23",
		dayToView: "Dia 23",
		contents: [
			{
				teacher: {
					name: "Camila Cavalieri",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/Camila-Cavalieri_RX2.png"
				},
				time: "17:30",
				subject: "Biologia",
				themes: [
					{ title: "Evolução" },
					{ title: "Biotecnologia" },
					{ title: "Cilco do nitrogênio" },
					{ title: "Fluxo de energia" },
					{ title: "Fitogeografia do Brasil" }
				]
			},
			{
				teacher: {
					name: "Dayana",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores_RX1/Jose-Jorge_RX.png"
				},
				time: "19:30",
				subject: "Literatura",
				themes: [
					{ title: "Interpretação de textos literários" },
					{ title: "Modernismo" },
					{ title: "Manifestações artísticas da contemporaneidade" },
					{ title: "Gêneros literários" },
					{ title: "Vanguardas europeias" }
				]
			}
		]
	},
	{
		day: "2019-10-24",
		dayToView: "Dia 24",
		contents: [
			{
				teacher: {
					name: "Caroline Azevedo",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/Caroline-Azevedo_RX2.png"
				},
				time: "17:30",
				subject: "Química",
				themes: [
					{ title: "eletroquímica" },
					{ title: "Identificação de Função orgânica" },
					{ title: "Identificação de Função orgânica" },
					{ title: "Isomeria Espacial" },
					{ title: "termoquímica" },
					{ title: "pH e Hidrólise Salina" }
				]
			},
			{
				teacher: {
					name: "Rômulo Bolivar",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/Romulo-Bolivar_RX2.png"
				},
				time: "19:30",
				subject: "Redação",
				themes: [
					{ title: "Contextualização do tema" },
					{ title: "Projeto de texto" },
					{ title: "Repertório sociocultural " },
					{ title: "Marca de autoria " },
					{ title: "Proposta de intervenção " }
				]
			}
		]
	},
	{
		day: "2019-10-25",
		dayToView: "Dia 25",
		contents: [
			{
				teacher: {
					name: "Marcelo Lameirão",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/Marcelo-Lameirao_RX2.png"
				},
				time: "17:30",
				subject: "História",
				themes: [
					{ title: "Era Vargas" },
					{ title: "Brasil Oligárquico" },
					{ title: "Ditadura Civil Militar" },
					{ title: "Escravidão" },
					{ title: "Redemocratização" }
				]
			},
			{
				teacher: {
					name: "Diego Viug",
					thumb:
						"https://reta-final-static.s3-sa-east-1.amazonaws.com/Professores-RX2/Diego-Viug_RX2.png"
				},
				time: "19:30",
				subject: "Matemática",
				themes: [
					{ title: "Estatística - média, moda e mediana" },
					{ title: "Proporcionalidade - regras de três " },
					{ title: "Função afim" },
					{ title: "Função quadrática" },
					{ title: "Porcentagem" }
				]
			}
		]
	}
];
