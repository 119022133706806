import styled from "styled-components";
import { MediaQueries } from "../../../../assets/mediaQueries";

interface IStyledProps {
	left?: boolean;
}

export const Arrow = styled.div<IStyledProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0px;
	right: ${({ left }) => (left ? "none" : "20px")};
	left: ${({ left }) => (left ? "20px" : "none")};
	transform: ${({ left }) => (!left ? "rotate(180deg)" : "")};
	z-index: 1;
	cursor: pointer;

	${MediaQueries.BIGGER_THAN_SMALL} {
		bottom: -30px;
		top: 0;
		left: ${({ left }) => (left ? "-30px" : "none")};
		right: ${({ left }) => (!left ? "-30px" : "none")};
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		left: ${({ left }) => (left ? "30px" : "none")};
		right: ${({ left }) => (!left ? "30px" : "none")};
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		left: ${({ left }) => (left ? "-30px" : "none")};
		right: ${({ left }) => (!left ? "-30px" : "none")};
	}
`;

export const WrapperIcon = styled.div<IStyledProps>``;
