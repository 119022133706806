import React from "react";

import arrow from "../../../../assets/images/home/arrow.png";

import { Arrow, WrapperIcon } from "./styles";

interface IArrowsProps {
	onClick?: any;
	left?: boolean;
}

const Arrows = ({ left, onClick }: IArrowsProps) => (
	<Arrow left={left} onClick={onClick}>
		<WrapperIcon left={left}>
			<img src={arrow} alt="Passar slide" />
		</WrapperIcon>
	</Arrow>
);

export default Arrows;
