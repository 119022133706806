import React from "react";

import useIsMobile from "../../../../hooks/use-is-mobile";
import { BreakPoints } from "../../../../assets/mediaQueries";

import {
	BalloonTeacher,
	BalloonContentTeacher,
	DayTeacher,
	NameTeacher,
	SubjectTeacher,
	ArrowTeacher,
	BalloonMobileTeacher
} from "./styles";

interface IProps {
	day: string;
	teacher: string;
	description: any;
}

const Balloon = ({ day, teacher, description }: IProps) => {
	const isMobile = useIsMobile(BreakPoints.medium);

	return (
		<>
			{!isMobile && (
				<>
					<BalloonTeacher>
						<BalloonContentTeacher>
							<DayTeacher>{day}</DayTeacher>
							<NameTeacher>{teacher}</NameTeacher>
							<SubjectTeacher>{description}</SubjectTeacher>
						</BalloonContentTeacher>
					</BalloonTeacher>
					<ArrowTeacher />
				</>
			)}

			{isMobile && (
				<BalloonMobileTeacher>
					<BalloonContentTeacher>
						<DayTeacher>{day}</DayTeacher>
						<NameTeacher>{teacher}</NameTeacher>
						<SubjectTeacher>{description}</SubjectTeacher>
					</BalloonContentTeacher>
				</BalloonMobileTeacher>
			)}
		</>
	);
};

export default Balloon;
