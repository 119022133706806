import React, { useState } from "react";
import { IDay, IAnswers, IStatistics } from "..";
import { Row, Col } from "../../../grid";
import { QuestionCard, ProgressDetails, OptionDetails } from "./styles";
import { RadioField } from "../../../fields";
import firebase from "firebase";
// @ts-ignore
import AnimatedNumber from "react-animated-number";
import ProgressBar from "../../../../components/progressBar";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

export interface IProps {
	days: IDay[];
	activeDay?: string;
	answers: IAnswers;
	userId: string;
	statistics: IStatistics;
}

export interface ICheckedOption {
	day: string;
	question: string;
	option: string;
}

const Quiz = ({ days, answers, activeDay, userId, statistics }: IProps) => {
	const [activeOptions, setActiveOptions] = useState<ICheckedOption[]>([]);

	const day = days.find(v => v.day === activeDay);

	const handleRadioField = ({ day, question, option }: ICheckedOption) => {
		setActiveOptions([
			...getFilteredActiveOptions({ day, question, option }),
			{
				day,
				question,
				option
			}
		]);
	};

	const hasOptionChecked = ({ day, question }: Partial<ICheckedOption>) =>
		!!activeOptions.find(
			activeOption =>
				activeOption.day === day && activeOption.question === question
		);

	const optionIsChecked = ({ day, question, option }: ICheckedOption) => {
		const checkedOption = activeOptions.find(
			activeOption =>
				activeOption.day === day &&
				activeOption.question === question &&
				activeOption.option === option
		);

		return !!checkedOption;
	};

	const getFilteredActiveOptions = ({ day, question }: ICheckedOption) =>
		activeOptions.filter(
			activeOption =>
				!(
					activeOption.day === day &&
					activeOption.question === question
				)
		);

	const handleSubmit = ({ day, question }: Partial<ICheckedOption>) => {
		const increment = firebase.firestore.FieldValue.increment(1);

		if (!question) {
			return;
		}
		const activeOption = activeOptions.find(
			item => item.day === day && item.question === question
		);
		if (!activeOption) {
			return;
		}
		const doc = firebase
			.firestore()
			.collection("answers")
			.doc(userId);

		const data = {
			[question]: activeOption.option
		};

		doc.set(data, { merge: true });

		firebase
			.firestore()
			.collection("statistics")
			.doc(question)
			.set({ [activeOption.option]: increment }, { merge: true });
	};

	const getAnswerForQuestion = (questionId: string) => {
		return answers[questionId];
	};

	const isQuestionAnswered = (questionId: string) => {
		return !!getAnswerForQuestion(questionId);
	};

	return (
		<VisibilitySensor partialVisibility>
			{({ isVisible }) => (
				<Spring
					config={{ duration: 900, delay: 800 }}
					to={{
						opacity: isVisible ? 1 : 0
					}}
				>
					{({ opacity }) => (
						<Row between="xs">
							{day &&
								day.questions.map(question => {
									if (isQuestionAnswered(question.id)) {
										const statistic: any =
											statistics[question.id];

										const total = statistic
											? Object.values(statistic)
													.map(
														value => value as number
													)
													.reduce(
														(
															accumulator: number,
															currentValue: number
														) =>
															accumulator +
															currentValue,
														0
													)
											: 0;

										return (
											<Col
												key={question.title}
												xs={12}
												sm={5}
												md={6}
											>
												<QuestionCard
													style={{
														opacity
													}}
													answered={isQuestionAnswered(
														question.id
													)}
												>
													<h3>
														Obrigado pelo voto! :)
													</h3>
													<ol>
														{statistic &&
															Object.keys(
																statistic
															).map(option => {
																const value =
																	statistic[
																		option
																	];
																const percentage = Math.floor(
																	(value /
																		total) *
																		100
																);
																const checked =
																	option ===
																	getAnswerForQuestion(
																		question.id
																	);
																return (
																	<li
																		key={`${question.title}_${option}`}
																		className={
																			checked
																				? "checked"
																				: undefined
																		}
																	>
																		<RadioField
																			checked={
																				checked
																			}
																		/>
																		<OptionDetails>
																			{
																				option
																			}
																			<ProgressDetails>
																				<ProgressBar
																					checked={
																						checked
																					}
																					value={
																						percentage
																					}
																				/>{" "}
																				<AnimatedNumber
																					value={
																						percentage
																					}
																					style={{
																						transition:
																							"1s ease-out"
																					}}
																					duration={
																						1000
																					}
																					stepPrecision={
																						0
																					}
																				/>

																				%
																			</ProgressDetails>
																		</OptionDetails>
																	</li>
																);
															})}
														{statistic &&
															question.options
																.filter(
																	v =>
																		!statistic[
																			v
																				.option
																		]
																)
																.map(
																	({
																		option
																	}) => (
																		<li
																			key={`${question.title}_${option}`}
																		>
																			<RadioField />
																			<OptionDetails>
																				{
																					option
																				}
																				<ProgressDetails>
																					<ProgressBar
																						value={
																							0
																						}
																					/>{" "}
																					0%
																				</ProgressDetails>
																			</OptionDetails>
																		</li>
																	)
																)}
													</ol>
												</QuestionCard>
											</Col>
										);
									}
									return (
										<Col
											key={question.title}
											xs={12}
											sm={5}
											md={6}
										>
											<QuestionCard key={question.title}>
												<h3
													dangerouslySetInnerHTML={{
														__html: question.title
													}}
												/>
												<ol>
													{question.options.map(
														({ option }) => {
															const checked = optionIsChecked(
																{
																	day:
																		day.day,
																	question:
																		question.id,
																	option
																}
															);

															return (
																<li
																	key={`${question.id}_${option}`}
																	className={
																		checked
																			? "checked"
																			: undefined
																	}
																	onClick={() =>
																		handleRadioField(
																			{
																				day:
																					day.day,
																				question:
																					question.id,
																				option
																			}
																		)
																	}
																>
																	<RadioField
																		checked={
																			checked
																		}
																	/>
																	{option}
																</li>
															);
														}
													)}
													<button
														onClick={() =>
															handleSubmit({
																day: day.day,
																question:
																	question.id
															})
														}
														disabled={
															!hasOptionChecked({
																day: day.day,
																question:
																	question.id
															})
														}
													>
														Votar
													</button>
												</ol>
											</QuestionCard>
										</Col>
									);
								})}
						</Row>
					)}
				</Spring>
			)}
		</VisibilitySensor>
	);
};

export default Quiz;
