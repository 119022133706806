import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { MediaQueries } from "../../assets/mediaQueries";

export const Wrapper = styled.ul`
	display: flex;

	${MediaQueries.BIGGER_THAN_SMALL} {
		justify-content: center;
	}
	padding: 24px;
`;

export interface IDayListItemProps {
	active?: boolean;
}

export const DayListItem = styled.div<IDayListItemProps>`
	width: 120px;
	height: 60px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	border-radius: 4px;
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
	position: relative;
	cursor: pointer;

	${MediaQueries.BIGGER_THAN_SMALL} {
		font-size: 24px;
	}

	${({ active }) =>
		active &&
		`
    background: #571D5D;
    color: #FFF;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    
    ::before {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        position: absolute;
        left: 50%;
        bottom: -14px;
        margin-left: -15px; 
		border-top: 15px solid #571D5D;
    }
`}

	${({ active }) =>
		!active &&
		`
    background: #FFF;
    color: #2B629F;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
`}
`;

const DayList = ({ items }: any) => {
	return (
		<PerfectScrollbar
			options={{
				suppressScrollY: true,
				useBothWheelAxes: true
			}}
		>
			<Wrapper>
				{items.map((item: any) => (
					<li
						style={{
							listStyleType: "none"
						}}
					>
						{item.component}
					</li>
				))}
			</Wrapper>
		</PerfectScrollbar>
	);
};

export default DayList;
