import styled from "styled-components";
import { MediaQueries } from "../../../../assets/mediaQueries";

export const BattleVersus = styled.img`
	width: 100%;
	max-width: 114px;
	margin: 0 auto;
	display: block;
	margin-bottom: 10px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-bottom: 0px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		max-width: 200px;
		transform: scale(1.6);
	}
`;

export const BattlePlayerThumb = styled.img`
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	display: block;

	${MediaQueries.BIGGER_THAN_WIDE} {
		max-width: 350px;
	}
`;

export const BattlePlayer = styled.div``;
