import React from "react";

import { Row, Col } from "../../../grid";
import {
	WrapperLogos,
	LogoProEnem,
	LogoRetaFinal,
	WrapperAction,
	ActionText,
	ActionButton,
	HeaderMobile,
	HeaderMobileAction,
	HeaderContainer
} from "./styles";
import Container from "../../../container";
import useIsMobile from "../../../../hooks/use-is-mobile";

interface IProps {
	handleClick(): void;
	handleClickAction(page: any): void;
}

const Header = ({ handleClick, handleClickAction }: IProps) => {
	const isMobile = useIsMobile();

	if (isMobile) {
		return (
			<HeaderMobile>
				<LogoProEnem />

				<HeaderMobileAction onClick={() => handleClick()}>
					<svg
						width="20"
						height="15"
						viewBox="0 0 37 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M37 29C37 28.4477 36.5523 28 36 28H7C6.44772 28 6 28.4477 6 29C6 29.5523 6.44772 30 7 30H36C36.5523 30 37 29.5523 37 29Z" />
						<path d="M37 1C37 0.447715 36.5523 0 36 0H12C11.4477 0 11 0.447715 11 1C11 1.55228 11.4477 2 12 2H36C36.5523 2 37 1.55228 37 1Z" />
						<path d="M37 15C37 14.4477 36.5523 14 36 14H1C0.447716 14 0 14.4477 0 15C0 15.5523 0.447716 16 1 16H36C36.5523 16 37 15.5523 37 15Z" />
					</svg>
				</HeaderMobileAction>
			</HeaderMobile>
		);
	}

	return (
		<HeaderContainer>
			<Container>
				<Row center="xs" middle="sm">
					<Col xs={12} sm={5} lg={6}>
						<WrapperLogos>
							<LogoProEnem />
							<LogoRetaFinal />
						</WrapperLogos>
					</Col>
					<Col xs={12} sm={7} lg={6}>
						<WrapperAction>
							<ActionText>
								Conquiste um NOTÃO na Redação do Enem
							</ActionText>
							<ActionButton
								onClick={() =>
									handleClickAction("maratonaIntensiva")
								}
							>
								Saiba mais
							</ActionButton>
						</WrapperAction>
					</Col>
				</Row>
			</Container>
		</HeaderContainer>
	);
};

export default Header;
