import React from "react";

import { Row, Col } from "../../../grid";
import {
	VideoIntroduction,
	WrapperIntroduction,
	TitleIntroduction,
	DescriptionIntroduction,
	ButtonIntroduction,
	HomeTop
} from "./styles";
import Container from "../../../container";
import VideoPlayer from "../../../video-player";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import { useStateContext } from "../../../../contexts/state";

const Headline = () => {
	const { dispatch } = useStateContext();
	const handleButton = () => {
		dispatch({
			type: "setModal",
			payload: {
				visible: true,
				type: "signUp"
			}
		});
	};
	return (
		<HomeTop>
			<Container>
				<Row between="sm" middle="xs">
					<Col xs={12} sm={7} lg={5}>
						<WrapperIntroduction>
							<VisibilitySensor partialVisibility>
								{({ isVisible }) => (
									<Spring
										config={{ duration: 500, delay: 300 }}
										to={{
											opacity: isVisible ? 1 : 0,
											transform: isVisible
												? "translateY(0)"
												: "translateY(50px)"
										}}
									>
										{({ opacity, transform }) => (
											<TitleIntroduction
												style={{ opacity, transform }}
											>
												As 5 semanas que vão garantir a
												sua aprovação!
											</TitleIntroduction>
										)}
									</Spring>
								)}
							</VisibilitySensor>
							<VisibilitySensor partialVisibility>
								{({ isVisible }) => (
									<Spring
										config={{ duration: 500, delay: 600 }}
										to={{
											opacity: isVisible ? 1 : 0,
											transform: isVisible
												? "translateY(0)"
												: "translateY(-25px)"
										}}
									>
										{({ opacity, transform }) => (
											<>
												<DescriptionIntroduction
													style={{
														opacity,
														transform
													}}
												>
													Na Reta Final você vai
													revisar todo o conteúdo mais
													importante para o Enem e
													ainda vai se divertir com a
													melhor equipe da internet!
												</DescriptionIntroduction>

												<ButtonIntroduction
													onClick={handleButton}
													style={{
														opacity,
														transform
													}}
												>
													Eu Quero
												</ButtonIntroduction>
											</>
										)}
									</Spring>
								)}
							</VisibilitySensor>
						</WrapperIntroduction>
					</Col>
					<Col xs={12} sm={5} lg={5}>
						<VideoIntroduction>
							<VideoPlayer
								url="https://youtu.be/hr53z2pMpk4"
								playing={false}
								height="100%"
								width="100%"
							/>
						</VideoIntroduction>
					</Col>
				</Row>
			</Container>
		</HomeTop>
	);
};

export default Headline;
