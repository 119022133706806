import Thumb1 from "../img/exatas/artur-neto.png";
import Thumb2 from "../img/exatas/camila.png";
import Thumb3 from "../img/exatas/carol.png";
import Thumb4 from "../img/exatas/leozao.png";
import Thumb5 from "../img/exatas/luiz-claudio.png";
import Thumb6 from "../img/exatas/marcos-alvarenga.png";
import Thumb7 from "../img/exatas/ricardo-fagundes.png";
import Thumb8 from "../img/exatas/thiago-oliveira.png";
import Thumb9 from "../img/exatas/vinicius-almeida.png";
import Thumb10 from "../img/exatas/vinicius-pecanha.png";
import Thumb11 from "../img/exatas/vitor-israel.png";
import Thumb12 from "../img/exatas/viug.png";

const professoresExatas = [
	Thumb1,
	Thumb2,
	Thumb3,
	Thumb4,
	Thumb5,
	Thumb6,
	Thumb7,
	Thumb8,
	Thumb9,
	Thumb10,
	Thumb11,
	Thumb12
];

export default professoresExatas;
