import React from "react";

// Dependencies
import ReactPlayer, { ReactPlayerProps } from "react-player";

const VideoPlayer = (props: ReactPlayerProps) => (
	<ReactPlayer controls={true} playing={true} {...props} />
);

export default VideoPlayer;
