import React from "react";

import arrow from "../../../../../assets/images/home/arrow.png";

import { Arrow } from "./styles";

interface IArrowsProps {
	onClick?: any;
	left?: boolean;
}

const Arrows = ({ left, onClick }: IArrowsProps) => (
	<Arrow left={left} onClick={onClick}>
		<img src={arrow} alt="Passar slide" />
	</Arrow>
);

export default Arrows;
