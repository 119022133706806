import React from "react";

// Components
import VideoPlayer from "../../video-player";

// Assets
import Circle from "./img/circle.svg";
import bgVideo from "./img/bg.png";
import { Row, Col } from "../../grid";
import Container from "../../container";
import {
	TaRolandoContainer,
	Vector1,
	Vector2,
	TaRolandoSubtitle,
	Text,
	Button,
	TaRolandoTitle,
	TaRolandoVideo,
	TaRolandoTop,
	TaRolandoBottom,
	BgVideo
} from "./styles";
import useIsMobile from "../../../hooks/use-is-mobile";

const TaRolando = () => {
	const isMobile = useIsMobile();

	return (
		<TaRolandoContainer>
			<TaRolandoTop>
				<BgVideo alt="Ao vivo" src={bgVideo} />
				<Vector1 id="Vector1">
					<img alt="Ao vivo" src={Circle} />
				</Vector1>

				{!isMobile && (
					<>
						<Vector2 />
					</>
				)}

				<Container>
					<Row reverse={!isMobile} middle="sm">
						<Col xs={12} sm={6} md={5} lg={5}>
							<TaRolandoTitle>Ao Vivo</TaRolandoTitle>
							<TaRolandoSubtitle>
								ProEnem Convida
							</TaRolandoSubtitle>
							<Text>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation
								ullamco laboris nisi ut aliquip ex ea commodo
								conse quat.
							</Text>
							<Button href="#">Baixar material</Button>
						</Col>
						<Col xs={12} sm={6} md={7} lg={7}>
							<TaRolandoVideo>
								<VideoPlayer
									url="https://www.youtube.com/watch?v=VyjMrCMiSGI"
									playing={false}
								/>
							</TaRolandoVideo>
						</Col>
					</Row>
				</Container>
			</TaRolandoTop>
			<TaRolandoBottom />
		</TaRolandoContainer>
	);
};

export default TaRolando;
