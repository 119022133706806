import styled from "styled-components";

import { MediaQueries } from "../../../../assets/mediaQueries";

import arrow from "../../../../assets/images/guests/arrow.png";

export const BalloonTeacher = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 180px;
	height: 180px;
	position: absolute;
	bottom: -110px;
	left: -120px;
	background: #fafafc;
	border-radius: 100%;
	box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
`;

export const BalloonContentTeacher = styled.div`
	width: 150px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		width: 250px;
	}
`;

export const DayTeacher = styled.p`
	font-family: Nunito;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	color: #df1d3d;

	text-align: center;
	margin-top: 10px;
	margin-bottom: 10px;

	${MediaQueries.SMALLER_THAN_WIDE} {
		font-size: 14px;
		line-height: 20px;
	}
`;

export const NameTeacher = styled.p`
	font-family: Nunito;
	font-style: bold;
	font-weight: 800;
	font-size: 20px;
	line-height: 28px;
	color: #4b1451;

	text-align: center;
	margin-bottom: 5px;

	${MediaQueries.SMALLER_THAN_WIDE} {
		font-size: 14px;
		line-height: 20px;
	}
`;

export const SubjectTeacher = styled.p`
	font-family: Nunito;
	font-size: 16px;
	line-height: 22px;
	color: #7c8195;

	text-align: center;

	${MediaQueries.SMALLER_THAN_WIDE} {
		font-size: 14px;
		line-height: 20px;
	}
`;

export const ArrowTeacher = styled.img.attrs(() => ({
	src: arrow,
	alt: "Flecha"
}))`
	position: absolute;
	bottom: 35px;
	left: -75px;

	${MediaQueries.TABLET_LANDSCAPE} {
		bottom: 0px;
		left: -20px;
	}
`;

export const BalloonMobileTeacher = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	width: 180px;
	margin: 0 auto;

	${MediaQueries.TABLET_PORTRAIT} {
		width: 250px;
		min-width: 180px;
		margin: 0 auto;
	}
`;
