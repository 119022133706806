import styled, { css } from "styled-components";
import { MediaQueries } from "../../../assets/mediaQueries";

import bg from "../../../assets/img/gabarito-exatas/bg.png";
import teachers from "../../../assets/img/gabarito-exatas/professores.png";

import { Section as OriginalSection } from "../gabarito-humanas/styles";

export const Section = styled(OriginalSection)`
	background: url(${bg}) left center no-repeat;
	background-color: #f0f1f7;
`;

export const Teachers = styled.div`
	background: url(${teachers}) center no-repeat;
	width: 564px;
	height: 733px;

	${MediaQueries.SMALL} {
		display: none;
	}

	${MediaQueries.TABLET} {
		margin: 0 auto;
	}
`;

export const Button = styled.button<{ active?: boolean }>`
	background: #1e9369;
	border-radius: 4px;
	border: none;
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.005em;
	color: #ffffff;
	margin-right: 10px;
	width: 50%;
	padding: 5px;
	cursor: pointer;

	${props =>
		!props.active &&
		css`
			background: #571d5d;
		`};
`;

export const Link = styled.a<{ active?: boolean }>`
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.005em;
	color: #571d5d;
	background: none;
	border: 1px solid #571d5d;
	width: 50%;
	text-align: center;
	text-decoration: none;
	padding: 5px;
	border-radius: 4px;

	${props =>
		!props.active &&
		css`
			color: #fff;
			background: #ffffff;
			border: 1px solid #f0f1f7;
			border-radius: 4px;
			color: #c8cbd9;
		`};
`;
