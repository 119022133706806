import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import { StateProvider } from "./contexts/state";

const firebaseConfig = {
	apiKey: "AIzaSyB1EKan56VWgGnq75p6-poY95ZNSraCW24",
	authDomain: "reta-final.firebaseapp.com",
	databaseURL: "https://reta-final.firebaseio.com",
	projectId: "reta-final",
	storageBucket: "reta-final.appspot.com",
	messagingSenderId: "565344301650",
	appId: "1:565344301650:web:56f6b8c71488a2faa1e58b",
	measurementId: "G-0ZWSBB8J4G"
};

firebase.initializeApp(firebaseConfig);

const render = (Component: any) => {
	return ReactDOM.render(
		<StateProvider>
			<Component />
		</StateProvider>,
		document.getElementById("root")
	);
};

render(App);

if ((module as any).hot) {
	(module as any).hot.accept("./App", () => {
		const NextApp = require("./App").default;
		render(NextApp);
	});
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
