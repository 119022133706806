import styled from "styled-components";

import { MediaQueries } from "../../../../assets/mediaQueries";

interface IImageProps {
	src: string;
}

export const ContainerTeacher = styled.div`
	display: flex;

	margin-top: 60px;

	${MediaQueries.SMALLER_THAN_WIDE} {
		justify-content: center;
		align-items: center;
		flex-direction: column;

		margin-top: 30px;
	}
`;

export const ImageTeacher = styled.img.attrs(({ src }) => ({
	src,
	alt: "Professor"
}))<IImageProps>`
	width: 450px;

	${MediaQueries.TABLET} {
		width: 250px;
	}

	${MediaQueries.SMALL} {
		width: 150px;
	}
`;
