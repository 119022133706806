import React, { useState, useEffect } from "react";
import styled from "styled-components";

export interface IProgressBarProps {
	checked?: boolean;
}

export const ProgressBarFill = styled.span`
	height: 10px;
	background: #74b4fe;
	display: block;
	border-radius: 6px;
	transition: all 1s;
`;

export const Wrapper = styled.span<IProgressBarProps>`
	width: 100%;
	height: 10px;
	display: block;
	background: #edf5ff;
	border-radius: 6px;
	margin-right: 10px;

	${({ checked }) =>
		checked &&
		`${ProgressBarFill} {
		background: #28B583;
}`}
`;
const ProgressBar = ({ value, checked }: any) => {
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setPercentage(value);
		}, 100);
	}, [value]);

	return (
		<Wrapper checked={checked}>
			<ProgressBarFill
				style={{
					width: `${percentage}%`
				}}
			/>
		</Wrapper>
	);
};

export default ProgressBar;
