import styled from "styled-components";
import { MediaQueries } from "../../../assets/mediaQueries";

import bg from "../../../assets/img/gabarito-humanas/bg.png";

export const Section = styled.section`
	width: 100%;
	position: relative;
	background: url(${bg}) right center no-repeat;
	background-color: #f0f1f7;
	background-size: 300%;
	display: flex;

	${MediaQueries.BIGGER_THAN_SMALL} {
		background-size: 80%;

		min-height: 100vh !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const Teachers = styled.img`
	display: none;

	${MediaQueries.BIGGER_THAN_SMALL} {
		display: block;
		width: 100%;
	}
`;

export const Logo = styled.img`
	width: 100%;
	max-width: 280px;
	margin: 0 auto;
	display: block;
	padding-top: 30px;
`;

export const Title = styled.h1`
	font-family: "Nunito";
	font-weight: 800;
	font-size: 24px;
	line-height: 1.15;
	letter-spacing: -0.01em;
	color: #25272d;
	padding: 20px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}
`;

export const Text = styled.p`
	font-size: 16px;
	line-height: 22px;
	color: #464646;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 30px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 40px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		max-width: 90%;
	}
`;
export const Button = styled.button`
	background: #1e9369;
	border-radius: 4px;
	border: none;
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.005em;
	color: #ffffff;
	margin-right: 10px;
	width: 50%;
	padding: 5px;
`;

export const Link = styled.a`
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.005em;
	color: #571d5d;
	background: none;
	border: 1px solid #571d5d;
	width: 50%;
	text-align: center;
	text-decoration: none;
	padding: 5px;
	border-radius: 4px;
`;
