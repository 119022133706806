import React, { useState, useEffect, useCallback } from "react";

import firebase from "firebase";
import styled from "styled-components";
import scrollIntoView from "scroll-into-view";
import Navigation from "./components/navigationMenu";
import Footer from "./components/footer";
import AulaoPresencial from "./components/sections/aulaoPresencial";
import AulaoDeVespera, {
	IAulaoDeVesperaProps
} from "./components/sections/aulaodeVespera";
import GabaritoHumanas from "./components/sections/gabarito-humanas";
import GabaritoExatas from "./components/sections/gabarito-exatas";
import Guests from "./components/sections/guests";
import Raiox from "./components/sections/raiox";
import Raiox2 from "./components/sections/raiox-2";
import Home from "./components/sections/home";
import GameShow from "./components/sections/game-show";
import NavigationMenuMobile from "./components/navigationMenuMobile";
import TaRolando from "./components/sections/ta-rolando";
import MaratonaIntensiva from "./components/sections/maratona-intensiva";
import Gretchen from "./assets/images/gretchen.png";
import Jefferson from "./assets/images/jefferson.png";
import SOS from "./components/sections/sos";

// Professores de Exatas - Jefferson
import Arthur from "./assets/images/aulao/exatas/artur.png";
import Camila from "./assets/images/aulao/exatas/camila.png";
import Carol from "./assets/images/aulao/exatas/carol.png";
import Leozao from "./assets/images/aulao/exatas/leozao.png";
import LuisClaudio from "./assets/images/aulao/exatas/luis-claudio.png";
import Marcos from "./assets/images/aulao/exatas/marcos.png";
import Rinaldi from "./assets/images/aulao/exatas/Rinaldi.png";
import Vitor from "./assets/images/aulao/exatas/vitor.png";
import Viug from "./assets/images/aulao/exatas/viug.png";

// Professores de Humanas - Gretchen
import Dayana from "./assets/images/aulao/humanas/dayana.png";
import Feitosa from "./assets/images/aulao/humanas/feitosa.png";
import Felipe from "./assets/images/aulao/humanas/felipe.png";
import Jj from "./assets/images/aulao/humanas/jj.png";
import Lameirao from "./assets/images/aulao/humanas/lameirao.png";
import LeandroSociologia from "./assets/images/aulao/humanas/leandro-v-soc.png";
import LeandroFilosofia from "./assets/images/aulao/humanas/leandro-v.png";
import LeandroA from "./assets/images/aulao/humanas/LeandroA.png";
import Otto from "./assets/images/aulao/humanas/otto.png";
import Romulo from "./assets/images/aulao/humanas/romulo.png";
import { SignUpModal, VideoModal } from "./components/modal";
import { useStateContext } from "./contexts/state";
import _ from "lodash";
import { MediaQueries } from "./assets/mediaQueries";

export const AppContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
`;

const SectionWrapper = styled.section`
	${MediaQueries.BIGGER_THAN_SMALL} {
		min-height: 80vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> * {
			min-height: 100%;
		}
	}
`;

export interface IPage {
	slug: string;
	date: string;
	name: string;
	component: React.ReactNode;
}
export interface ICoordinate {
	slug: string;
	coordinate: number;
}

const configAulaoDeVespera: IAulaoDeVesperaProps[] = [
	{
		title: "10 horas de aula ao vivo!!!",
		legend: (
			<>
				Você vai chegar no Enem acertando tudo! <br />A concorrência
				chora… Um aulão todo focado em Ciências Humanas, Linguagens e
				Redação. Você vai poder revisar o conteúdo, ganhar prêmios e se
				divertir com uma equipe de professores que tem um compromisso
				com a sua aprovação.
			</>
		),
		image: Gretchen,
		handleClick: () => console.log("aaa"),
		teachers: [
			Dayana,
			Feitosa,
			Felipe,
			Jj,
			Lameirao,
			LeandroSociologia,
			Otto,
			LeandroA,
			LeandroFilosofia,
			Romulo
		],
		isHumans: true
	},
	{
		title: "Um dia inteiro de revisão",
		legend: (
			<>
				Você vai passar o dia todo revisando conteúdos importantes para
				chegar mandando muito bem na prova de Matemática e Ciências da
				Natureza do Enem 2019. Estude, divirta-se e conquiste prêmios
				com a melhor equipe de professores da internet. Sinto cheiro de
				aprovação…
			</>
		),
		image: Jefferson,
		handleClick: () => console.log("aaa"),
		teachers: [
			Arthur,
			Camila,
			Carol,
			Leozao,
			LuisClaudio,
			Marcos,
			Rinaldi,
			Vitor,
			Viug
		],
		isHumans: false
	}
];

const pages: IPage[] = [
	{
		slug: "convidados",
		date: "14/10 a 18/10",
		name: "Convidados",
		component: <Guests />
	},
	{
		slug: "raiox",
		date: "14/10 a 18/10",
		name: "Raio X",
		component: <Raiox />
	},
	{
		slug: "gameshow",
		date: "19/10",
		name: "Game Show",
		component: <GameShow />
	},
	{
		slug: "raiox-2",
		date: "21/10 a 25/10",
		name: "Raio X",
		component: <Raiox2 />
	},
	{
		slug: "aulaoPresencial",
		date: "27/10",
		name: "Aulao Presencial",
		component: <AulaoPresencial />
	},
	{
		slug: "sos-1",
		date: "14/10 a 18/10",
		name: "SOS Humanas",
		component: <SOS theme="humanas" />
	},
	{
		slug: "aulaoDeVespera",
		date: "27/10",
		name: "Aulao De Vespera",
		component: <AulaoDeVespera {...configAulaoDeVespera[0]} />
	},
	{
		slug: "gabaritoHumanas",
		date: "02/11",
		name: "Gabarito Humanas",
		component: <GabaritoHumanas />
	},
	{
		slug: "sos-2",
		date: "14/10 a 18/10",
		name: "SOS Exatas",
		component: <SOS theme="exatas" />
	},
	{
		slug: "aulaoDeVespera-2",
		date: "09/11",
		name: "Aulao De Vespera",
		component: (
			<AulaoDeVespera gutterTop={true} {...configAulaoDeVespera[1]} />
		)
	},
	{
		slug: "gabaritoExatas",
		date: "10/11",
		name: "Gabarito Exatas",
		component: <GabaritoExatas />
	}
];

const App = () => {
	const [activePage, setActivePage] = useState(pages[0].slug);
	const [coordinates, setCoordinates] = useState<ICoordinate[]>([]);
	const [activeMenu, setActiveMenu] = useState(false);
	const [activeMenuDesktop, setActiveMenuDesktop] = useState(false);

	const pagesRefs: { [string: string]: HTMLDivElement } = {};

	const getPages = () =>
		pages.map(page => ({
			...page,
			active: activePage === page.slug
		}));

	const generatePagesCoordinates = () => {
		const getCoordinate = (slug: string) =>
			pagesRefs[slug].getBoundingClientRect().top;
		const getCoordinates = pages
			.filter(({ slug }) => getCoordinate(slug))
			.map(({ slug }) => ({
				slug,
				coordinate: getCoordinate(slug)
			}));

		setCoordinates(getCoordinates.filter(Boolean));
	};

	// Memoized functions are only called once :)
	const memoizedGeneratePagesCoordinates = useCallback(
		generatePagesCoordinates,
		[]
	);

	useEffect(() => {
		memoizedGeneratePagesCoordinates();
	}, [memoizedGeneratePagesCoordinates]);

	useEffect(() => {
		firebase.auth().signInAnonymously();
	}, []);

	const handleScroll = (data: React.UIEvent<HTMLDivElement>) => {
		const scrollTop = data.currentTarget.scrollTop;
		const scrollBottom =
			coordinates[coordinates.length - 1].coordinate +
			data.currentTarget.clientHeight -
			200;

		if (scrollTop >= scrollBottom) {
			return setActiveMenuDesktop(false);
		}

		if (scrollTop < data.currentTarget.clientHeight) {
			setActivePage(pages[0].slug);
		}

		const whereAmI = coordinates
			.filter(coordinate => scrollTop + 200 >= coordinate.coordinate)
			.pop() as ICoordinate;

		if (!!whereAmI) {
			setActiveMenuDesktop(true);

			const currentPage = pages.find(page => page.slug === whereAmI.slug);

			if (!currentPage) {
				return;
			}
			setActivePage(currentPage.slug);
		} else {
			setActiveMenuDesktop(false);
		}
	};

	function scrollTo(item: any) {
		try {
			if (!pagesRefs[item]) {
				throw new Error("Ref inválido");
			}

			scrollIntoView(
				pagesRefs[item]!,
				{
					align: {
						top: 0
					}
				},
				(type: string) => {
					if (type !== "complete") {
						return;
					}
				}
			);
		} catch (error) {
			console.log(error);
		}
	}

	function handleClickNavigation(item: IPage) {
		scrollTo(item.slug);
	}

	const { state, dispatch } = useStateContext();

	useEffect(() => {
		firebase.auth().onAuthStateChanged(user => {
			if (!user) {
				return;
			}

			dispatch({ type: "setAuth", payload: user });

			firebase
				.firestore()
				.collection("questions")
				.get()
				.then(snapshot => {
					const documents = snapshot.docs.map(doc => {
						const data = doc.data();

						return {
							id: doc.id,
							day: data.day,
							title: data.title,
							options: data.options,
							type: data.type
						};
					});
					const days = _.uniq(documents.map(doc => doc.day));
					const daysWithQuestions = days.map(day => {
						const questionsForTheDay = documents
							.filter(doc => doc.day === day)
							.map(doc => ({
								id: doc.id,
								title: doc.title,
								options: doc.options,
								type: doc.type
							}));

						return {
							day,
							questions: questionsForTheDay
						};
					});

					dispatch({
						type: "setQuestions",
						payload: daysWithQuestions
					});
				});

			firebase
				.firestore()
				.collection("answers")
				.doc(user.uid)
				.onSnapshot(snapshot => {
					const document = snapshot.data() || {};
					dispatch({
						type: "setAnswers",
						payload: document
					});
				});

			firebase
				.firestore()
				.collection("statistics")
				.onSnapshot(snapshot => {
					const documents = snapshot.docs.reduce(
						(accumulator: any, currentValue: any) => {
							const data = currentValue.data();

							return {
								...accumulator,
								[currentValue.id]: data
							};
						},
						{}
					);
					dispatch({
						type: "setStatistics",
						payload: documents
					});
				});
		});
	}, [dispatch]);

	return (
		<AppContainer onScroll={handleScroll}>
			<SignUpModal
				isVisible={state.modal.visible && state.modal.type === "signUp"}
				onClose={() => {
					dispatch({
						type: "setModal",
						payload: { visible: false }
					});
				}}
			/>
			<VideoModal
				embed={state.modal.embed}
				isVisible={state.modal.visible && state.modal.type === "video"}
				onClose={() => {
					dispatch({
						type: "setModal",
						payload: { visible: false }
					});
				}}
			/>
			{activeMenuDesktop && (
				<Navigation
					handleClick={handleClickNavigation}
					pages={getPages()}
				/>
			)}
			{activeMenu && (
				<NavigationMenuMobile
					handleClickAction={() => scrollTo("maratonaIntensiva")}
					handleClick={e => {
						handleClickNavigation(e);
						setActiveMenu(!activeMenu);
					}}
					handleClose={() => setActiveMenu(!activeMenu)}
					pages={getPages()}
				/>
			)}

			<div
				ref={(el: HTMLDivElement) => {
					if (!pagesRefs["home"]) {
						return (pagesRefs["home"] = el);
					}
				}}
			>
				<Home
					handleClick={() => setActiveMenu(!activeMenu)}
					handleScroll={page => scrollTo(page)}
				/>
			</div>

			<TaRolando />

			{getPages().map(page => (
				<SectionWrapper
					key={page.slug}
					id={page.slug}
					ref={(el: HTMLDivElement) => {
						if (!pagesRefs[page.slug]) {
							return (pagesRefs[page.slug] = el);
						}
					}}
				>
					{page.component}
				</SectionWrapper>
			))}

			<div
				ref={(el: HTMLDivElement) => {
					if (!pagesRefs["maratonaIntensiva"]) {
						return (pagesRefs["maratonaIntensiva"] = el);
					}
				}}
			>
				<MaratonaIntensiva />
			</div>
			<Footer handleClick={() => scrollTo("home")} />
		</AppContainer>
	);
};

export default App;
