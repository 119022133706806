import styled, { css } from "styled-components";
import { IProps as ISectionProps } from "./index";
import topWave from "../../../assets/sos/bg-sos-topo.png";
import bottomWave from "../../../assets/sos/bg-sos-baixo.png";
import { MediaQueries } from "../../../assets/mediaQueries";

import bg1 from "../../../assets/img/raiox-2/bg-1.png";

export const Section = styled.section<ISectionProps>`
	width: 100%;
	position: relative;
	overflow-x: hidden;
	padding-bottom: 40px;

	h2 {
		font-family: "Nunito";
		text-align: center;
		font-weight: 800;
		font-size: 22px;
		line-height: 1.25;
		letter-spacing: -0.01em;
		color: #25272d;
		margin-bottom: 20px;
	}

	p {
		text-align: center;
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: #666b7b;
		margin-top: 10px;
	}

	${MediaQueries.BIGGER_THAN_SMALL} {
		h2 {
			margin-top: 30px;
			font-size: 28px;
		}

		h2,
		p {
			padding-left: 20px;
			padding-right: 20px;
			text-align: left;
		}
	}
`;

export const Logo = styled.img`
	margin: 0 auto;
	display: block;
	width: 100%;
	margin-top: 30px;
	margin-bottom: 40px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		max-width: 220px;
		margin-top: 20px;
		margin-bottom: 0px;
	}
`;

export const FrontLayer = styled.div<ISectionProps>`
	position: relative;
	z-index: 2;
	/* ${({ theme }) => `
		background: ${theme === "humanas" ? "#edf5ff" : "#FDCB76"};
	`} */

${props =>
	props.theme === "humanas" &&
	css`
		background: #edf5ff;
	`}


	${props =>
		props.theme === "exatas" &&
		css`
			background: url(${bg1}) #fdcb76;
			background-position: top;
			background-size: 100%;
			background-repeat: no-repeat;
		`}


	${MediaQueries.BIGGER_THAN_SMALL} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100vh;
	}
`;

export const TopWave = styled.div`
	width: 100%;
	height: 18px;
	background: url(${topWave});
	background-size: 100%;
	background-position: top center;
	background-repeat: repeat-x;

	${MediaQueries.TABLET_PORTRAIT} {
		height: 44px;
	}

	${MediaQueries.TABLET_LANDSCAPE} {
		height: 59px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		height: 100px;
		background-size: 100% 100%;
	}
`;

export const BottomWave = styled.div`
	width: 100%;
	height: 18px;
	background: url(${bottomWave});
	background-size: cover;
	background-position: top center;
	background-repeat: repeat-x;
	position: relative;

	${MediaQueries.TABLET_PORTRAIT} {
		height: 44px;
	}

	${MediaQueries.TABLET_LANDSCAPE} {
		height: 59px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		height: 100px;
		background-size: 100% 100%;
	}
`;
