import React from "react";

import { WrapperItem, ImageItem, DateItem } from "./styles";

interface IProps {
	date: string;
	logo: string;
	onClick(): void;
}

const Item = ({ date, logo, onClick }: IProps) => {
	return (
		<WrapperItem>
			<ImageItem
				alt="Evento ProEnem"
				src={logo}
				onClick={() => onClick()}
			/>
			<DateItem onClick={() => onClick()}>{date}</DateItem>
		</WrapperItem>
	);
};

export default Item;
