import React from "react";
import { Section, Logo, Title, Text } from "./styles";

import Container from "../../container";
import RaioxDias from "../raiox/dias";
import logo from "../../../assets/img/raiox-2/logo.png";
import { Row, Col } from "../../grid";

import { raioxSegundaSemana } from "../../../config/raiox";

const Raiox2 = () => (
	<Section>
		<Container>
			<Row middle="xs">
				<Col xs={12} sm={4}>
					<Logo src={logo} />
				</Col>
				<Col xs={12} sm={7}>
					<Title>Os temas mais cobrados</Title>
					<Text>
						Chegou a hora de revisar! Todos os dias aulas com os
						assuntos que mais caem no Enem. Tá sentindo esse cheiro
						no ar? É a sua aprovação!{" "}
						<span role="img" aria-label="emoji sorrindo">
							😜
						</span>
					</Text>
				</Col>
			</Row>
			<RaioxDias days={raioxSegundaSemana} />
		</Container>
	</Section>
);

export default Raiox2;
