import React, {
	createContext,
	useCallback,
	useReducer,
	useContext
} from "react";
import _ from "lodash";

const initialState = {
	modal: {
		visible: false,
		type: "",
		embed: ""
	},
	auth: { uid: "" },
	fetched: {
		answers: false,
		statistics: false
	},
	collections: {
		questions: [],
		answers: {},
		statistics: {}
	}
};

const StateContext = createContext({});

export const StateProvider = ({ children }: any) => {
	const reducer = (state: any, action: any) => {
		if (action.type === "setModal") {
			return {
				...state,
				modal: {
					visible: action.payload.visible,
					type: action.payload.type || "",
					embed: action.payload.embed || ""
				}
			};
		}
		if (action.type === "setAuth") {
			return {
				...state,
				auth: action.payload
			};
		}
		if (action.type === "setAnswers") {
			const newState = {
				...state,
				fetched: {
					...state.fetched,
					answers: true
				},
				collections: {
					...state.collections,
					answers: action.payload
				}
			};
			return newState;
		}
		if (action.type === "setStatistics") {
			const newState = {
				...state,
				fetched: {
					...state.fetched,
					statistics: true
				},
				collections: {
					...state.collections,
					statistics: action.payload
				}
			};
			return newState;
		}
		if (action.type === "setQuestions") {
			const questions = _.sortBy(
				action.payload.map((item: any) => {
					const month = item.day.substring(0, 5) === "Dia 0" ? 10 : 9; // Gambiarra gostosa <3
					return { ...item, month };
				}),
				["month", "day"]
			);
			const newState = {
				...state,
				collections: {
					...state.collections,
					questions
				}
			};
			return newState;
		}
		return state;
	};
	const memoizedReducer = useCallback(reducer, []);
	const [state, dispatch] = useReducer(memoizedReducer, initialState);
	return (
		<StateContext.Provider value={{ state, dispatch }}>
			{children}
		</StateContext.Provider>
	);
};

export const StateConsumer = StateContext.Consumer;

export const useStateContext: any = () => useContext(StateContext);
