import React, { useState, useEffect } from "react";

import {
	Section,
	ButtonNavTop,
	Nav,
	NavInstitucional,
	Wrapper,
	WrapperMobile,
	WrapperMobileApp,
	Title,
	TitleNews,
	Links,
	LinksColumn,
	LinksColumnMobile,
	Link,
	Form,
	Field,
	Button,
	SocialLinks,
	Facebook,
	Instagram,
	Youtube,
	Twitter,
	AccordionIcon
} from "./styles";
import Container from "../container";
import firebase from "firebase";
import imgGooglePlay from "../../assets/img/footer/google-play.png";
import Loader from "../loader";
import useIsMobile from "../../hooks/use-is-mobile";

interface IProps {
	handleClick(): void;
}

interface ILinks {
	title: string;
	items: {
		title: string;
		url: string;
	}[];
}

const FooterLinks: ILinks[] = [
	{
		title: "Nossos Planos",
		items: [
			{
				title: "Enem Intensivo",
				url: "https://www.proenem.com.br/enem/enem-2019-intensivo/"
			},
			{
				title: "Enem Intensivo Plus",
				url: "https://www.proenem.com.br/enem/enem-2019-intensivo-plus/"
			},
			{
				title: "Intensivo Redação",
				url: "https://redacao-intensivo.proenem.com.br/"
			}
		]
	},
	{
		title: "Tudo sobre o ENEM",
		items: [
			{
				title: "Língua Portuguesa",
				url: "https://www.proenem.com.br/enem/lingua-portuguesa/"
			},
			{
				title: "Literatura",
				url: "https://www.proenem.com.br/enem/literatura/"
			},
			{
				title: "Redação",
				url: "https://www.proenem.com.br/enem/redacao/"
			},
			{
				title: "Matemática",
				url: "https://www.proenem.com.br/enem/matematica"
			},
			{
				title: "Física",
				url: "https://www.proenem.com.br/enem/fisica/"
			},
			{
				title: "Química",
				url: "https://www.proenem.com.br/enem/quimica/"
			},
			{
				title: "Biologia",
				url: "https://www.proenem.com.br/enem/biologia/"
			},
			{
				title: "História",
				url: "https://www.proenem.com.br/enem/historia/"
			},
			{
				title: "Geografia",
				url: "https://www.proenem.com.br/enem/geografia/"
			},
			{
				title: "Filosofia",
				url: "https://www.proenem.com.br/enem/filosofia/"
			},
			{
				title: "Sociologia",
				url: "https://www.proenem.com.br/enem/sociologia/"
			},
			{
				title: "Interpretação",
				url: "https://www.proenem.com.br/enem/interpretacao/"
			},
			{
				title: "Inglês",
				url: "https://www.proenem.com.br/enem/ingles/"
			},
			{
				title: "Espanhol",
				url: "https://www.proenem.com.br/enem/espanhol/"
			}
		]
	},
	{
		title: "Gabaritos",
		items: [
			{
				title: "Gabaritos ENEM 2018",
				url: "https://gabaritos.proenem.com.br/gabarito/enem/2018"
			},
			{
				title: "Gabaritos ENEM 2017",
				url: "https://gabaritos.proenem.com.br/gabarito/enem/2017"
			},
			{
				title: "Gabaritos ENEM 2016",
				url: "https://gabaritos.proenem.com.br/gabarito/enem/2016"
			},
			{
				title: "Gabaritos ENEM 2015",
				url: "https://gabaritos.proenem.com.br/gabarito/enem/2015"
			}
		]
	}
];

const FooterAccordion = ({
	item,
	isMobile
}: {
	item: ILinks;
	isMobile: boolean;
}) => {
	console.log("isMobile", isMobile);
	const [active, setActive] = useState(false);

	useEffect(() => {
		setActive(!isMobile);
		// eslint-disable-next-line
	}, [isMobile]);

	function handleClick() {
		if (!isMobile) return;

		setActive(!active);
	}

	return (
		<Wrapper>
			<Title onClick={() => handleClick()}>
				{item.title}

				{isMobile && (
					<AccordionIcon
						active={active}
						width="14"
						height="9"
						viewBox="0 0 14 9"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1.7793 1L7.15896 8L12.544 1"
							stroke="#202020"
							stroke-width="2"
							stroke-miterlimit="10"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</AccordionIcon>
				)}
			</Title>
			{active && (
				<Links>
					{item.items.map((link, index) => (
						<Link key={index} href={link.url} target="_blank">
							{link.title}
						</Link>
					))}
				</Links>
			)}
		</Wrapper>
	);
};

const Footer = ({ handleClick }: IProps) => {
	const [email, setEmail] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const isMobile = useIsMobile();
	const [sent, setSent] = useState(false);

	const handleSubmit = (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		firebase
			.firestore()
			.collection("newsletter")
			.add({ email })
			.then(() => {
				setEmail("");
				setSent(true);
				setIsLoading(false);
			});
	};

	return (
		<Section>
			{isLoading && <Loader />}
			<Container>
				<ButtonNavTop onClick={() => handleClick()}>
					&nbsp;
				</ButtonNavTop>

				<Nav>
					{FooterLinks.map(item => (
						<FooterAccordion isMobile={isMobile} item={item} />
					))}

					<Wrapper>
						<WrapperMobile>
							<TitleNews>
								Assine a Newsletter do Vestibulando
							</TitleNews>
							<Form onSubmit={handleSubmit}>
								<Field
									type="text"
									name="email"
									placeholder="Insira seu email aqui"
									value={email}
									onChange={(event: any) =>
										setEmail(event.target.value)
									}
								/>
								<Button>
									{sent ? "Enviado! :)" : "Enviar"}
								</Button>
							</Form>
						</WrapperMobile>

						<WrapperMobileApp>
							<TitleNews>Aplicativo Proenem</TitleNews>
							<Link
								href="https://play.google.com/store/apps/details?id=com.prosucesso.proenem"
								target="_blank"
							>
								<img src={imgGooglePlay} alt="Google Play" />
							</Link>
						</WrapperMobileApp>
					</Wrapper>
				</Nav>
				<NavInstitucional>
					<LinksColumnMobile>
						<Link
							href="https://www.proenem.com.br/quem-somos/"
							target="_blank"
						>
							Quem somos
						</Link>
						<Link
							href="https://atendimento.proenem.com.br/hc/pt-br"
							target="_blank"
						>
							Central de atendimento
						</Link>
						<Link
							href="https://www.proenem.com.br/termos-de-uso/"
							target="_blank"
						>
							Termos de uso
						</Link>
						<Link
							href="https://www.proenem.com.br/materiais-para-download/"
							target="_blank"
						>
							Materiais para download
						</Link>
						<Link
							href="https://www.proenem.com.br/blog/"
							target="_blank"
						>
							Blog
						</Link>
					</LinksColumnMobile>

					<SocialLinks>
						<Youtube
							href="https://www.youtube.com/user/ProENEMOficial"
							target="_blank"
						></Youtube>
						<Instagram
							href="https://www.instagram.com/proenem/"
							target="_blank"
						></Instagram>
						<Facebook
							href="https://www.facebook.com/proenem/"
							target="_blank"
						></Facebook>
						<Twitter
							href="https://twitter.com/proenemoficial"
							target="_blank"
						></Twitter>
					</SocialLinks>
				</NavInstitucional>
			</Container>
		</Section>
	);
};

export default Footer;
