import styled from "styled-components";

import { MediaQueries } from "../../../../assets/mediaQueries";

export const HomeTop = styled.div`
	position: relative;
	z-index: 2;
`;

export const WrapperIntroduction = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 10px;
	padding-bottom: 0;

	${MediaQueries.BIGGER_THAN_SMALL} {
		padding-left: 20px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		padding-left: 0;
	}
`;

export const VideoIntroduction = styled.div`
	height: 280px;
	border-radius: 4px;
	margin: 0px 10px;
	background-color: #ddd2df;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin: 0px 20px;
	}
	${MediaQueries.BIGGER_THAN_WIDE} {
		margin: 0px;
	}
`;

export const TitleIntroduction = styled.h2`
	font-family: Nunito;
	font-style: normal;
	font-weight: 800;
	font-size: 32px;
	line-height: 44px;
	color: #ffffff;
	text-align: center;
	
	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}
/* 
	${MediaQueries.SMALLER_THAN_WIDE} {
		font-size: 28px;
		line-height: 35px;
		text-align: center;
	} */
`;

export const DescriptionIntroduction = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #fafafc;
	text-align: center;
	margin-top: 10px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}

	/* width: 390px; */
	/* margin-top: 15px; */

	/* ${MediaQueries.SMALLER_THAN_WIDE} {
		margin-top: 15px;
		text-align: center;
	} */
`;

export const ButtonIntroduction = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 40px;
	margin-top: 20px;
	margin-bottom: 20px;
	cursor: pointer;
	background: #feb647;
	border-radius: 4px;
	font-family: Nunito;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #25272d;
	margin-left: auto;
	margin-right: auto;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-left: 0;
		margin-right: 0;
	}
`;
