import React from "react";
import styled from "styled-components";

export interface IProps {
	checked?: boolean;
}

const Field = styled.div<IProps>`
	margin-right: 10px;
	width: 15px;
	height: 15px;
	border-radius: 100%;
	border: 2px solid #eeeeee;
	flex-shrink: 0;
	position: relative;
	transition: all 0.15s;

	${({ checked }) =>
		checked &&
		`
    
    border: 2px solid #28B583;
    
    ::before {
        content: '';
        width: 7px;
        height: 7px;
        background: #28B583;
        position: absolute;
        left: 2px;
        top: 2px;
	    border-radius: 100%;
    }`}
`;

const RadioField = ({ checked }: IProps) => {
	return <Field checked={checked} />;
};

export default RadioField;
