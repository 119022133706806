import styled from "styled-components";
import { Section as DefaultSection } from "../styles";
import { MediaQueries } from "../../../assets/mediaQueries";

export const Section = styled(DefaultSection)`
	background: #571d5d;
	display: flex;
	flex-direction: column;
	padding-top: 50px;
	position: relative;

	${MediaQueries.BIGGER_THAN_SMALL} {
		padding-top: 0px;
	}
`;
