import React from "react";
import {
	Section,
	Element1,
	Element2,
	Logo,
	Title,
	Text,
	BgTop,
	BgBottom,
	Content
} from "./styles";
import logo from "../../../assets/img/raiox/logo.png";

import Container from "../../container";
import RaioxDias from "./dias";
import { Row, Col } from "../../grid";

import { raioxPrimeiraSemana } from "../../../config/raiox";

const Raiox = () => (
	<Section>
		<BgTop />
		<Element1 />
		<Element2 />
		<Content>
			<Container>
				<Row middle="xs">
					<Col xs={12} sm={4}>
						<Logo src={logo} />
					</Col>
					<Col xs={12} sm={7}>
						<Title>Os temas mais cobrados</Title>
						<Text>
							Chegou a hora de revisar! Todos os dias aulas com os
							assuntos que mais caem no Enem. Tá sentindo esse
							cheiro no ar? É a sua aprovação!{" "}
							<span role="img" aria-label="emoji sorrindo">
								😜
							</span>
						</Text>
					</Col>
				</Row>
				<RaioxDias days={raioxPrimeiraSemana} />
			</Container>
		</Content>
		<BgBottom />
	</Section>
);

export default Raiox;
