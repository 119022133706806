import styled from "styled-components";
import MainContainer from "../../container";
// import { MediaQueries } from "../../../assets/mediaQueries";
import Slick from "react-slick";
import { MediaQueries } from "../../../assets/mediaQueries";

import arrow from "../../../assets/images/arrow-aulao.png";

export const Section = styled.div`
	background: #fff;
	width: 100%;
	margin: 0;
	position: relative;
	display: flex;
	flex-direction: column;
`;

export const Container = styled(MainContainer)`
	min-height: initial;
`;

export const AulaoDeVesperaLogo = styled.img`
	width: 100%;
	max-width: 280px;
	margin: 0 auto;
	display: block;
	margin-bottom: 30px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		max-width: 230px;
		margin: 0;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		max-width: 300px;
		transform: rotate(12deg);
		left: 20px;
		position: relative;
	}
`;

export const AulaoDeVesperaSlider = styled(Slick)`
	.slick-track {
		margin: 0px;
	}

	.slick-dots li button:before {
		color: #df1d3d !important;
	}
`;

export const InformationsSlider = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		h3 {
			font-size: 26px;
		}
	}
`;

export interface ITitleSliderProps {
	isHumans?: boolean;
}

export const TitleSlider = styled.h3<ITitleSliderProps>`
	font-family: Nunito;
	font-weight: 800;
	font-size: 22px;
	line-height: 1;
	color: #000000;
	position: relative;
	top: 10px;

	${({ isHumans }) =>
		isHumans &&
		`
		font-size: 20px;
	`}
`;

export const ArrowTeacher = styled.img.attrs(() => ({
	src: arrow
}))``;

export const ImageSlider = styled.img`
	width: 150px;

	${MediaQueries.SMALLER_THAN_WIDE} {
		width: 100px;
	}
`;

export const AulaoDeVesperaSliderContainer = styled.div`
	width: 100%;
	margin-bottom: 40px;
	padding-left: 10px;
	padding-right: 10px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		/* padding-left: 30px; */
	}
	${MediaQueries.BIGGER_THAN_MEDIUM} {
		padding: 0;
		/* padding-left: 30px; */
	}
`;

export const AulaoDetails = styled.div`
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		padding-left: 0px;
		padding-right: 0px;
		margin-bottom: 20px;
		text-align: left;

		h2 {
			font-size: 28px;
		}
	}
`;

export const Apresentadora = styled.img`
	width: 100%;
	max-width: 300px;
	display: block;
	margin: 0 auto;

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		max-width: 500px;
		position: relative;
		transform: scale(1.1);
		margin-bottom: 40px;
	}
`;
