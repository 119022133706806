import styled from "styled-components";

interface IStyledProps {
	left?: boolean;
}

export const Arrow = styled.div<IStyledProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	right: ${({ left }) => (left ? "none" : "-10px")};
	left: ${({ left }) => (left ? "-10px" : "none")};
	cursor: pointer;
	z-index: 2;
`;

export const WrapperIcon = styled.div<IStyledProps>`
	transform: ${({ left }) => (left ? "rotate(0deg)" : "rotate(180deg)")};
`;
