import React from "react";
import {
	NavigationMenuMobileContainer,
	NavigationMenuMobileHeader,
	CloseButton,
	ButtonAction,
	NavigationMenuMobileContent,
	NavigationWave
} from "./styles";
import { NavigationItem } from "../navigationMenu";

interface IProps {
	handleClick(e: any): void;
	handleClickAction(): void;
	handleClose(): void;
	pages: any[];
}

const closeIcon = (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="#ffffff"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.9991 1.00091L1 11M10.9991 11L1.00045 1"
			stroke="#FAFAFC"
			stroke-width="2"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const NavigationMenuMobile = ({
	handleClick,
	pages,
	handleClose,
	handleClickAction
}: IProps) => {
	return (
		<NavigationMenuMobileContainer>
			<NavigationMenuMobileHeader>
				<CloseButton onClick={() => handleClose()}>
					{closeIcon}
				</CloseButton>
				<h2>Esteja pronto para redação do Enem</h2>
				<div>
					<ButtonAction
						onClick={() => {
							handleClickAction();
							handleClose();
						}}
					>
						Saiba Mais
					</ButtonAction>
				</div>

				<NavigationWave />
			</NavigationMenuMobileHeader>

			<NavigationMenuMobileContent>
				{pages.map(item => (
					<NavigationItem
						active={item.active}
						item={item}
						key={item.slug}
						handleClick={() => handleClick(item)}
					/>
				))}
			</NavigationMenuMobileContent>

			{/* {pages.map(page => )} */}
		</NavigationMenuMobileContainer>
	);
};

export default NavigationMenuMobile;
