import React from "react";

import {
	ContainerSchedule,
	TitleSchedule,
	ContainerSlider,
	SliderSchedule,
	TimeLine,
	ScheduleWave
} from "./styles";

import Arrows from "./arrows";
import Item from "./item";
import Container from "../../../container";

import logoAulaoPresencial from "../../../../assets/images/home/slide/logo-aulao-presencial.png";
import logoAulaoVespera from "../../../../assets/images/home/slide/logo-aulao-vespera.png";
import logoGabarito from "../../../../assets/images/home/slide/logo-gabarito.png";
import logoGameshow from "../../../../assets/images/home/slide/logo-game-show.png";
import logoProenemConvida from "../../../../assets/images/home/slide/logo-proenem-convida.png";
import logoRaioX from "../../../../assets/images/home/slide/logo-raio-x.png";
import logoSosProenem from "../../../../assets/images/home/slide/logo-sos-proenem.png";
import { string, any } from "prop-types";

interface IScheduleItem {
	date: string;
	logo: any;
	page: any;
}

const items: IScheduleItem[] = [
	{ date: "07/10 a 11/10", logo: logoProenemConvida, page: "convidados" },
	{ date: "14/10 a 18/10", logo: logoRaioX, page: "raiox" },
	{ date: "19 de Outubro", logo: logoGameshow, page: "gameshow" },
	{ date: "21/10 a 25/10", logo: logoRaioX, page: "raiox-2" },
	{
		date: "27 de Outubro",
		logo: logoAulaoPresencial,
		page: "aulaoPresencial"
	},
	{ date: "28/10 a 01/11", logo: logoSosProenem, page: "sos-1" },
	{ date: "02 de Novembro", logo: logoAulaoVespera, page: "aulaoDeVespera" },
	{ date: "03 de Novembro", logo: logoGabarito, page: "gabaritoHumanas" },
	{ date: "04/11 a 08/11", logo: logoSosProenem, page: "sos-2" },
	{
		date: "09 de Novembro",
		logo: logoAulaoVespera,
		page: "aulaoDeVespera-2"
	},
	{ date: "10 de Novembro", logo: logoGabarito, page: "gabaritoExatas" }
];

interface IProps {
	handleScroll(page: any): void;
}

const Schedule = ({ handleScroll }: IProps) => {
	const renderItems = () => {
		const mappedItems = items.map(item => (
			<Item
				onClick={() => handleScroll(item.page)}
				logo={item.logo}
				date={item.date}
			/>
		));

		return mappedItems;
	};

	return (
		<ContainerSchedule id="schedulo">
			<ScheduleWave />
			<Container>
				<TitleSchedule>O que vai rolar na Reta Final</TitleSchedule>
				<ContainerSlider>
					<SliderSchedule
						infinite={false}
						arrows
						dots={false}
						slidesToShow={4}
						slidesToScroll={1}
						nextArrow={<Arrows />}
						prevArrow={<Arrows left />}
						responsive={[
							{
								breakpoint: 420,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 1
								}
							},
							{
								breakpoint: 768,
								settings: {
									slidesToShow: 3,
									slidesToScroll: 1
								}
							}
						]}
					>
						{renderItems()}
					</SliderSchedule>
					<TimeLine />
				</ContainerSlider>
			</Container>
		</ContainerSchedule>
	);
};

export default Schedule;
