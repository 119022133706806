import React, { useState, useEffect } from "react";
import {
	Section,
	Subjects,
	Subject,
	Time,
	Title,
	Subtitle,
	Themes,
	Theme,
	Button,
	Teacher
} from "./styles";

import { IDay } from "../../../../config/raiox";
import DayList, { DayListItem } from "../../../day-list";
import { Row, Col } from "../../../grid";
import { useStateContext } from "../../../../contexts/state";

export interface IProps {
	days: IDay[];
}

const RaioxDias = ({ days }: IProps) => {
	const [activeDay, setActiveDay] = useState("");
	const { dispatch } = useStateContext();

	useEffect(() => {
		setActiveDay(days[0].day);
	}, [days]);

	const dayListItems = days.map(({ day, dayToView }: any) => ({
		id: day,
		component: (
			<DayListItem
				key={day}
				onClick={() => setActiveDay(day)}
				active={day === activeDay}
			>
				{dayToView}
			</DayListItem>
		)
	}));

	const day = days.find(v => v.day === activeDay);

	const handleButton = (dayContent: any) => {
		dispatch({
			type: "setModal",
			payload: {
				visible: true,
				type: "video",
				embed:
					'<iframe width="560" height="315" src="https://www.youtube.com/embed/eISr0vQhRhc?autoPlay=1&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
			}
		});
	};

	return (
		<Section>
			<DayList items={dayListItems} />

			<Subjects>
				<Row around="md">
					{day &&
						day.contents.map((content, index) => (
							<Col
								xs={12}
								sm={5}
								md={6}
								smOffset={index === 1 ? 1 : 0}
								mdOffset={0}
							>
								<Subject key={index}>
									<header>
										<Title>{content.subject}</Title>
										<Time>{content.time}</Time>
									</header>
									<Subtitle>Temas da aula:</Subtitle>
									<Themes>
										{content.themes.map((theme, index) => (
											<Theme key={index}>
												&bull; {theme.title}
											</Theme>
										))}
									</Themes>

									<Teacher>
										<img
											src={content.teacher.thumb}
											alt={content.teacher.name}
										/>
										{content.teacher.name}
									</Teacher>
									<Button
										onClick={() => handleButton(content)}
										active={false}
									>
										Assistir Ao Vivo
									</Button>
								</Subject>
							</Col>
						))}
				</Row>
			</Subjects>
		</Section>
	);
};

export default RaioxDias;
