import styled from "styled-components";
import MainContainer from "../../container";

import { MediaQueries } from "../../../assets/mediaQueries";

export const Container = styled(MainContainer)`
	height: 100%;
`;

export const AulaoPresencialContainer = styled.div`
	background: #fff;
	width: 100%;
	margin: 0;
	position: relative;
	/* padding: 2rem 0; */
	/* position: relative; */

	display: flex;
	flex-direction: column;
`;

export const AulaoPresencialHeader = styled.div<{ active?: boolean }>`
	${MediaQueries.TABLET_PORTRAIT} {
		height: 25vh;
	}

	display: flex;
	align-items: center;
	/* background: red; */
`;

export const AulaoPresencialContent = styled.div`
  /* ${MediaQueries.TABLET_PORTRAIT} {
    flex: 1;
  } */

  flex: 1;
  display: flex;
  align-items: center;

  /* background: blue; */
`;

export const AulaoPresencialLogo = styled.div`
	padding-top: 40px;
	padding-left: 20px;
	padding-right: 20px;
	img {
		width: 100%;
		max-width: 300px;
		display: block;
		margin: 0 auto;
	}
`;

export const AulaoPresencialCubes = styled.div`
	${MediaQueries.SMALL} {
		display: none;
	}
	/* width: 100%; */
	justify-content: center;
	display: flex;
	align-items: center;
	width: 25%;

	position: absolute;
	width: 200px;
	top: -50px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		top: -60px;
	}

	img {
		border: 0;
		width: 100%;
	}
`;

export const AulaoPresencialTexts = styled.div`
	padding-top: 30px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;

	h2 {
		font-size: 24px;
		font-weight: 800;
	}

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
		padding-left: 30px;
		padding-right: 30px;
	}
`;

export const AulaoPresencialBubble = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	${MediaQueries.SMALL} {
		margin-bottom: 20px;
		/* width: 90%; */
	}

	img {
		max-width: 100% !important;
	}
`;

export const AulaoPresencialBubbleImage = styled.img`
	width: 100%;
`;

export const AulaoPresencialBubbleYear = styled.span`
	/* border-radius: 4px; */
	/* border-bottom-left-radius: 9px; */
	background: #fff;
	color: #4b1451;

	font-size: 19px;
	position: absolute;
	right: -10%;
	bottom: 7%;
	padding: 8px 20px;
	box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);

	border: 1px solid rgba(0, 0, 0, 0.07);
	border-radius: 6px;
`;

export const AulaoPresencialBubbleImagePicture = styled.div`
	left: 5%;
	margin-top: 5%;
	width: 85%;
	position: absolute;

	img {
		width: 100%;
		border-radius: 5px;
	}
`;
