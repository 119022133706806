import React from "react";
import { Section, Button, Link } from "./styles";
import logo from "../../../assets/img/gabarito-exatas/logo.png";
import {
	Logo,
	Title,
	Text,
	Wrapper,
	Teachers
} from "../gabarito-humanas/styles";
import teachers from "../../../assets/img/gabarito-humanas/professores.png";

import Container from "../../container";
import useIsMobile from "../../../hooks/use-is-mobile";
import { Row, Col } from "../../grid";
import { useStateContext } from "../../../contexts/state";

const GabaritoExatas = () => {
	const isMobile = useIsMobile();
	const { dispatch } = useStateContext();

	const handleButton = () => {
		dispatch({
			type: "setModal",
			payload: { type: "signUp", visible: true }
		});
	};

	return (
		<Section>
			<Container>
				<Row middle="sm">
					<Col sm={5}>
						<Teachers src={teachers} />
					</Col>
					<Col xs={12} sm={6} smOffset={1}>
						<Logo src={logo} />
						<Title>
							Simule sua nota e veja as chances de passar
						</Title>
						<Text>
							Aqui no Proenem você poderá simular a sua nota de
							acordo com o TRI e também poderá acompanhar, ao vivo
							no YouTube, os comentários da equipe que{" "}
							<strong>acertou 100%</strong> das questões do{" "}
							<strong>Enem 2018</strong>.
							{isMobile && (
								<>
									<br />
									<br />
									Plataforma perfeita faz assim!
								</>
							)}
							{!isMobile && <> Plataforma perfeita faz assim!</>}
						</Text>
						<Wrapper>
							<Button onClick={handleButton}>Me avisa aí</Button>
							<Link
								href="https://gabaritos.proenem.com.br"
								target="_blank"
							>
								Simular nota
							</Link>
						</Wrapper>
					</Col>
				</Row>
			</Container>
		</Section>
	);
};

export default GabaritoExatas;
