import styled, { keyframes } from "styled-components";

export const rotateAnimations = keyframes`
from { 
    transform: rotate(0deg); 
} to { 
    transform: rotate(360deg); 
}
`;

const dash = keyframes`
    0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

export const Spinner = styled.div`
	margin: 0;
	padding: 0;

	.spinner {
		animation: ${rotateAnimations} 2s linear infinite;
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -25px 0 0 -25px;
		width: 35px;
		height: 35px;

		.path {
			stroke: #4b1451;
			stroke-linecap: round;
			animation: ${dash} 1.5s ease-in-out infinite;
		}
	}
`;

export const Wrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 11;
	display: flex;
	align-items: center;
	justify-content: center;
`;
