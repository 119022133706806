import styled from "styled-components";
import Container from "../../container";

import { MediaQueries } from "../../../assets/mediaQueries";

import element1 from "../../../assets/img/raiox/element-1.png";
import element2 from "../../../assets/img/raiox/element-2.png";
import bgTop from "../../../assets/img/raiox/bg-top.png";
import bgBottom from "../../../assets/img/raiox/bg-bottom.png";

export const Section = styled.section`
	width: 100%;
	position: relative;
	margin-top: 80px;

	${Container} {
		overflow: hidden;
	}
`;

export const Content = styled.div`
	background: #53a4c6;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;

	${MediaQueries.SMALL} {
		text-align: center;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		flex: 1;
		height: calc(100% - 123px);
	}
`;

export const BgTop = styled.div`
	position: absolute;
	background-image: url(${bgTop});
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	z-index: 1;
	width: 100%;
	height: 168px;
	top: -160px;
	left: 0;
`;

export const BgBottom = styled.div`
	background-image: url(${bgBottom});
	background-size: 100%;
	background-repeat: no-repeat;
	z-index: 1;
	width: 100%;
	height: 30px;
	display: block;
	margin-top: -1px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		height: 60px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		height: 105px;
	}
`;

export const Element1 = styled.div`
	display: none;

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		position: absolute;
		background-image: url(${element1});
		background-size: 100%;
		background-repeat: no-repeat;
		z-index: 12;
		width: 188px;
		height: 491px;
		bottom: -40px;
		left: -120px;
		display: block;
	}
`;

export const Element2 = styled.div`
	position: absolute;
	background-image: url(${element2});
	background-size: 100%;
	background-repeat: no-repeat;
	z-index: 12;
	width: 213px;
	height: 237px;
	top: -230px;
	right: 20px;
	display: none;

	${MediaQueries.BIGGER_THAN_SMALL} {
		display: block;
	}
`;

export const Logo = styled.img`
	max-width: 368px;
	width: 100%;
	display: block;
	margin: 0 auto;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-left: 30px;
	}
`;

export const Title = styled.h1`
	font-weight: 800;
	font-family: "Nunito";
	font-size: 24px;
	line-height: 1.15;
	letter-spacing: -0.01em;
	color: #fafafc;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		padding-left: 20px;
		margin-top: 0px;
		text-align: left;
	}
`;

export const Text = styled.p`
	font-size: 16px;
	line-height: 22px;
	color: #f0f1f7;
	text-align: center;
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}
`;
