import React from "react";
import styled from "styled-components";
import { MediaQueries } from "../../../assets/mediaQueries";

export const AulaoButton = styled.button`
	background: #4b1451;
	font-family: Nunito Sans;

	font-weight: bold;
	font-size: 16px;

	&:hover {
		background: #3b1040;
	}

	border-radius: 4px;

	color: #fff;
	border: 0;

	padding: 8px 45px;

	cursor: pointer;

	${MediaQueries.SMALL} {
		width: 100%;
		margin-bottom: 20px;
	}
`;

const AulaoButtonWrapper = ({
	children,
	onClick
}: {
	children: any;
	onClick(): void;
}) => <AulaoButton onClick={() => onClick()}>{children}</AulaoButton>;

export default AulaoButtonWrapper;
