import React, { useState, useEffect } from "react";
import logoHumanas from "../../../assets/sos/sos-humanas.png";
import logoExatas from "../../../assets/sos/sos-exatas.png";
import { Section, Logo, FrontLayer, TopWave, BottomWave } from "./styles";
import { Grid, Row, Col } from "../../grid";
import Quiz from "./quiz";
import Loader from "../../../components/loader";
import DayList, { DayListItem } from "../../day-list";
import { useStateContext } from "../../../contexts/state";

export interface IProps {
	theme: "humanas" | "exatas";
}

export interface IOption {
	option: string;
}

export interface IQuestion {
	id: string;
	title: string;
	options: IOption[];
}

export interface IDay {
	day: string;
	questions: IQuestion[];
}

export interface IAnswers {
	[key: string]: string;
}

export interface IStatistics {
	[key: string]: {
		[key: string]: string;
	}[];
}

const SOS = ({ theme }: IProps) => {
	const [activeDay, setActiveDay] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const { state } = useStateContext();
	const { answers, statistics } = state.collections;
	const days = state.collections.questions.filter((day: any) => {
		const hasQuestionsForTheme = !!day.questions.find(
			(question: any) => question.type === theme
		);
		return hasQuestionsForTheme;
	});
	const userId = state.auth.uid;

	useEffect(() => {
		if (days.length === 0) {
			return;
		}
		if (!activeDay) {
			setActiveDay(days[0].day);
		}
	}, [days, activeDay]);

	useEffect(() => {
		if (
			!state.fetched.answers ||
			!state.fetched.statistics ||
			days.length === 0
		) {
			return;
		}
		setIsLoading(false);
	}, [state.fetched, days]);

	const handleDayListItem = (day: string) => {
		setActiveDay(day);
	};

	const dayListItems =
		!!days &&
		days.map(({ day }: any) => ({
			id: day,
			component: (
				<DayListItem
					key={day}
					onClick={() => handleDayListItem(day)}
					active={activeDay === day}
				>
					{day}
				</DayListItem>
			)
		}));

	return (
		<Section theme={theme}>
			{isLoading && <Loader />}
			{theme === "humanas" && <TopWave />}
			<FrontLayer theme={theme}>
				<Grid>
					<Row middle="sm">
						<Col xs={12} sm={4}>
							<Logo
								src={
									theme === "humanas"
										? logoHumanas
										: logoExatas
								}
							/>
						</Col>
						<Col xs={12} sm={7} md={8}>
							<h2>Escolha o tema das aulas!</h2>
							<p>
								Aqui você é o big boss e tem o poder de escolher
								um assunto relacionado ao primeiro dia do Enem.
								Os temas mais votados ganham aulas especiais. E
								aí, vamos votar?
							</p>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<DayList items={dayListItems} />
						</Col>
					</Row>
					<Quiz
						answers={answers}
						activeDay={activeDay}
						days={days}
						statistics={statistics}
						userId={userId}
					/>
				</Grid>
			</FrontLayer>
			{theme === "humanas" && <BottomWave />}
		</Section>
	);
};

export default SOS;
