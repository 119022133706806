import styled from "styled-components";
import Slick from "react-slick";

import logoGuests from "../../../assets/images/guests/logo-guests.png";
import { MediaQueries } from "../../../assets/mediaQueries";

export const Section = styled.section`
	width: 100%;
`;

export const GuestsSection = styled(Section)`
	height: auto;
	${MediaQueries.BIGGER_THAN_MEDIUM} {
		padding-bottom: 40px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		padding-bottom: 200px;
	}
`;

export const WrapperInformationsGuests = styled.div`
	display: flex;
	flex-direction: column;

	${MediaQueries.BIGGER_THAN_SMALL} {
		padding-left: 40px;
	}
`;

export const LogoGuests = styled.img.attrs(() => ({
	src: logoGuests,
	alt: "Proenem Convida"
}))`
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
	max-width: 280px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-left: 0;
		margin-right: 0;
		margin-top: 20px;
	}
`;

export const TitleGuests = styled.h2`
	font-family: Nunito;
	font-weight: 800;
	font-size: 23px;
	line-height: 1.35;
	color: #25272d;
	margin-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		padding: 0;
		text-align: left;
	}
`;

export const InformationsGuests = styled.p`
	font-family: Nunito Sans;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #666b7b;
	margin-bottom: 30px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
	}
`;

export const ButtonGuests = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 195px;
	height: 40px;
	position: relative;
	z-index: 2;

	font-family: Nunito;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	color: #ffffff;

	background: #4b1451;
	border-radius: 4px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	border: none;
	cursor: pointer;

	${MediaQueries.BIGGER_THAN_SMALL} {
		width: 100%;
		max-width: 300px;
		margin-left: 0;
		margin-right: 0;
	}
`;

export const ContainerSliderGuest = styled.div`
	position: relative;

	${MediaQueries.BIGGER_THAN_SMALL} {
		transform: scale(0.9);
		left: -20px;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		transform: scale(1);
	}
`;

export const SliderGuests = styled(Slick)`
	.slick-track {
		margin: 0px;
	}

	.slick-dots li button:before {
		color: #df1d3d !important;
	}
`;
