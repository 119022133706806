import React from "react";
import { Spinner, Wrapper } from "./styles";

interface IProps {
	color?: string;
	size?: number;
}

const Loader = (props: IProps) => {
	return (
		<Wrapper>
			<Spinner>
				<svg className="spinner" viewBox="0 0 50 50">
					<circle
						className="path"
						cx="25"
						cy="25"
						r="20"
						fill="none"
						strokeWidth="5"
					/>
				</svg>
			</Spinner>
		</Wrapper>
	);
};

export default Loader;
