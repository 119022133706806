import styled, { css } from "styled-components";
import Container from "../../container";
import { Section } from "../styles";
import Bg from "./img/bg.png";
import { MediaQueries } from "../../../assets/mediaQueries";
import IcoCheck from "./img/ico-check.svg";

export const MaratonaIntensivaContainer = styled(Section)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;

	${Container} {
		position: relative;
	}

	padding-top: 30px;
`;

export const MaratonaIntensivaLogoWrapper = styled.img`
	width: 100%;
	max-width: 280px;
	display: block;
	margin: 0 auto;
	margin-bottom: 20px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-bottom: 0px;
	}
`;

export const MaratonaIntensivaContent = styled.div`
	${MediaQueries.SMALL} {
		padding: 0 10px;
	}
`;

export const MaratonaIntensivaTitle = styled.h2`
	color: #25272d;
	font-family: "Nunito";
	font-weight: 800;
	font-size: 28px;
	line-height: 1.25;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 20px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-top: 20px;
		font-size: 24px;
		text-align: left;
	}
`;

export const MataronaIntensivaText = styled.p`
	color: #666b7b;
	font-family: "Nunito Sans";
	font-size: 16px;
	line-height: 1.25;
	margin-bottom: 20px;
	padding-left: 30px;
	padding-right: 30px;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
		padding-left: 20px;
		padding-right: 20px;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		padding-left: 20px;
		padding-right: 20px;
		text-align: left;
		margin-bottom: 0px;
	}
`;

export const MaratonaIntensivaList = styled.ul`
	align-items: center;
	display: flex;
	flex-direction: column;
	list-style-type: none;
	padding-left: 18px;
	padding-right: 18px;

	li {
		margin-bottom: 20px;
		font-size: 15px;
		color: #3a3e47;
		font-family: "Nunito Sans";
		font-weight: 600;
		display: flex;
		align-items: center;

		::before {
			content: "";
			width: 13px;
			height: 12px;
			background: url(${IcoCheck}) no-repeat;
			margin-right: 10px;
			display: inline-block;
		}
	}

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-top: 20px;
		padding-left: 100px;
		padding-right: 0px;
		flex-wrap: wrap;
		flex-direction: row;

		li {
			width: 50%;
		}
	}
	${MediaQueries.BIGGER_THAN_MEDIUM} {
		margin-top: 0px;

		li {
			padding-left: 10px;
		}
	}
`;

export const MaratonaIntensivaFooter = styled.div`
	background: url(${Bg}) center bottom no-repeat;
	background-size: cover;
	position: relative;
	width: 100%;

	${Container} {
		align-items: flex-end;
		display: flex;
		justify-content: flex-end;
		padding-top: 0;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		margin-top: -120px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		margin-top: 40px;
	}

	${MediaQueries.SMALL} {
		background-position: 20% bottom;
		background-size: cover;
		overflow: hidden;
	}
`;

export const MaratonaThumb = styled.figure`
	display: none;

	${MediaQueries.BIGGER_THAN_SMALL} {
		bottom: 0;
		left: 0;
		z-index: 2;
		display: block;
	}
`;

const Vector = styled.figure`
	position: absolute;
	z-index: 1;

	${MediaQueries.SMALL} {
		img {
			transform: scale(0.7);
		}
	}
`;

export const Vector1 = styled(Vector)`
	top: -150px;
	left: -70px;

	${MediaQueries.SMALL} {
		top: -30px;
		left: -50px;
	}

	${MediaQueries.TABLET_PORTRAIT} {
		top: 140px;
	}

	${MediaQueries.TABLET_LANDSCAPE} {
		top: 140px;
	}

	${MediaQueries.BIGGER_THAN_WIDE} {
		top: 46px;
		left: -30px;
	}
`;

export const Vector2 = styled(Vector)`
	right: 130px;
	top: 10px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		right: 282px;
		top: 63px;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		right: 50%;
		margin-right: -200px;
		top: 80px;
	}
`;

export const Vector3 = styled(Vector)`
	bottom: 200px;
	right: 93px;

	${MediaQueries.SMALL} {
		bottom: 160px;
		right: -70px;
	}

	${MediaQueries.TABLET_PORTRAIT} {
		bottom: 260px;
		right: 13px;
	}

	${MediaQueries.TABLET_LANDSCAPE} {
		bottom: 260px;
		right: 13px;
	}
`;

export const Vector4 = styled(Vector)`
	bottom: -18px;
	right: -18px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		right: -40px;
	}
`;

export const MaratonaIntensivaPricing = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		padding-right: 60px;
	}
`;

export const MaratonaIntensivaPricingWrapper = styled.div`
	margin-bottom: 15px;
	padding-top: 120px;
	padding-left: 30px;
	padding-right: 30px;

	> strong {
		font-family: "Nunito";
		font-weight: 800;
		font-size: 32px;
		color: #fdcb76;
		display: block;
		margin-bottom: 7px;
	}

	p {
		align-items: baseline;
		color: #fafafc;
		display: flex;

		> span {
			font-family: "Nunito";
			font-size: 18px;
			line-height: 26px;
			margin-right: 13px;
		}

		> strong {
			color: #ffffff;
			font-family: "Nunito";
			font-weight: 900;
			font-size: 48px;
		}
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		p {
			justify-content: center;
		}
	}
`;

export const Button = styled.a<{ outline?: boolean }>`
	border-radius: 4px;
	font-family: "Nunito";
	font-weight: bold;
	font-size: 16px;
	height: 40px;
	line-height: 1;
	align-items: center;
	display: flex;
	justify-content: center;

	${({ outline }) => {
		if (outline) {
			return css`
				background: transparent;
				color: #feb647;
				text-decoration-line: underline;
				width: 117px;
			`;
		}

		return css`
			background: #feb647;
			color: #965307;
			width: 150px;
			text-decoration: none;
		`;
	}}
`;

export const MaratonaActions = styled.nav`
	align-items: center;
	display: flex;
	justify-content: center;

	${Button} {
		&:first-child {
			margin-right: 10px;
		}
	}

	${MediaQueries.BIGGER_THAN_SMALL} {
		justify-content: flex-start;
	}
`;

export const MaratonaObs = styled.a`
	color: #fffdf5;
	font-family: "Nunito Sans";
	font-size: 12px;
	text-align: left;
	text-decoration: none;
	margin: 0 auto;
	display: block;
	padding: 15px 20px 25px 15px;

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		text-align: right;
	}
`;
