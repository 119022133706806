import styled from "styled-components";

import { MediaQueries } from "../../../../../assets/mediaQueries";

export const WrapperItem = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #fff;
`;

export const ImageItem = styled.img`
	width: 200px;
	cursor: pointer;

	${MediaQueries.SMALLER_THAN_WIDE} {
		width: 100px;
	}
`;

export const DateItem = styled.p`
	cursor: pointer;

	font-family: Nunito;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	color: #571d5d;
	margin-top: 10px;

	position: relative;

	&::before {
		content: "";
		position: absolute;
		height: 10px;
		width: 10px;
		border-radius: 100%;
		background-color: #df1d3d;
		top: -25px;
		left: 50%;

		${MediaQueries.SMALL} {
			top: -31px;
		}
	}

	${MediaQueries.SMALL} {
		margin-top: 10px;
		font-size: 14px;
		line-height: 20px;
	}
`;
