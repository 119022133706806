import styled from "styled-components";

import logoProEnem from "../../../../assets/images/home/logo-proenem.png";
import logoRetaFinal from "../../../../assets/images/home/logo-reta-final.png";

import { MediaQueries } from "../../../../assets/mediaQueries";

export const HeaderContainer = styled.header`
	background: #571d5d;
	padding: 20px;
`;

export const WrapperLogos = styled.div`
	display: flex;
	align-items: center;
	height: 90px;

	${MediaQueries.SMALLER_THAN_WIDE} {
		margin-left: 0px;
	}
`;

export const LogoProEnem = styled.img.attrs(() => ({
	src: logoProEnem,
	alt: "Logo Proenem"
}))`
	padding: 10px 25px 5px 0px;
	border-right: 1px solid #fff;

	${MediaQueries.SMALLER_THAN_WIDE} {
		width: 155px;
	}
`;

export const LogoRetaFinal = styled.img.attrs(() => ({
	src: logoRetaFinal,
	alt: "Logo Reta Final"
}))`
	padding-top: 30px;
	padding-left: 25px;

	${MediaQueries.SMALL} {
		padding-top: 8px;
	}

	${MediaQueries.TABLET_PORTRAIT} {
		padding-top: 8px;
	}

	${MediaQueries.SMALLER_THAN_WIDE} {
		width: 125px;
	}
`;

export const WrapperAction = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	${MediaQueries.SMALL} {
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 20px;
	}
`;

export const ActionText = styled.h3`
	font-family: Nunito;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #ffff;

	margin-right: 10px;

	${MediaQueries.SMALL} {
		text-align: center;
		margin-bottom: 10px;
		margin-right: 0px;
	}
`;

export const ActionButton = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 100px;
	background: #4b9eff;
	border-radius: 4px;
	cursor: pointer;

	font-family: Nunito;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.005em;
	color: #102740;
`;

export const HeaderMobile = styled.div`
	z-index: 980;
	${LogoProEnem} {
		width: 100px;
		padding: 0;
		border-right: 0;
	}

	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);

	background: #571d5d;
	width: 100%;

	position: fixed;
	top: 0;
	margin-top: 0;

	display: flex;
	align-items: center;
	padding: 10px 15px;
	justify-content: space-between;
`;

export const HeaderMobileLogo = styled.div`
	width: 40%;

	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		border: 0;
	}
`;

export const HeaderMobileAction = styled.button`
	border: 0;
	background: transparent;
	cursor: pointer;

	svg {
		fill: #fff;
	}
`;
