import styled, { css } from "styled-components";
import { MediaQueries } from "../../../../assets/mediaQueries";

import clock from "../../../../assets/img/ico-clock.png";

export const Section = styled.div`
	margin-top: 0px;
	clear: both;
	text-align: left;
	padding-left: 20px;
	padding-right: 20px;
`;

export const Days = styled.div`
	display: flex;

	${MediaQueries.BIGGER_THAN_SMALL} {
		justify-content: center;
	}

	${MediaQueries.SMALL} {
		overflow-x: scroll;
		padding-bottom: 20px;
	}
`;

export const Day = styled.button<{ active?: boolean }>`
	background: #ffffff;
	border-radius: 4px;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #1e4853;
	border: none;
	padding: 20px 33px;

	&:not(:last-child) {
		margin-right: 20px;
	}

	${props =>
		props.active &&
		css`
			background: #571d5d;
			color: #fff;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
			position: relative;

			&::before {
				content: "";
				width: 0;
				height: 0;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				position: absolute;
				left: 50%;
				bottom: -14px;
				margin-left: -15px;
				border-top: 15px solid #571d5d;
			}
		`};
`;

export const Subjects = styled.div`
	margin-top: 20px;
	position: relative;
	z-index: 20;
	display: flex;
	flex-direction: column;
`;

export const Subject = styled.div`
	width: 100%;
	height: 320px;
	background: #ffffff;
	border-radius: 4px;
	padding-top: 23px;
	position: relative;
	padding-bottom: 45px;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const Time = styled.div`
	color: #505462;
	font-size: 12px;
	line-height: 16px;
	margin-right: 24px;
	background: url(${clock}) left center no-repeat;
	padding-left: 20px;
`;

export const Title = styled.strong`
	color: #000000;
	font-size: 20px;
	line-height: 28px;
	margin-left: 24px;
`;

export const Subtitle = styled.p`
	margin-top: 6px;
	font-size: 16px;
	line-height: 22px;
	font-weight: 600;
	margin-bottom: 10px;
	margin-left: 24px;
`;
export const Themes = styled.ul`
	list-style: none;
	margin-left: 24px;
`;

export const Theme = styled.li`
	font-family: Nunito Sans;
	font-size: 14px;
	line-height: 20px;
	color: #7c8195;
`;

export const Button = styled.button<{ active?: boolean }>`
	display: block;
	width: 100%;
	background: #4b1451;
	color: #fff;
	border-radius: 4px;
	text-align: center;
	padding: 5px 0;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.005em;
	outline: 0;
	border: none;
	position: absolute;
	bottom: 0;
	left: 0;
	cursor: pointer;
	height: 40px;
	${props =>
		props.active &&
		css`
			background: #1e9369;
		`};
`;
export const Teacher = styled.div`
	display: flex;
	align-items: center;
	margin-left: 24px;
	margin-top: 10px;
`;
