import styled, { keyframes } from "styled-components";
import { MediaQueries } from "../../assets/mediaQueries";
import {
	NavigationBullWrapp,
	NavgationAction,
	NavigationBull
} from "../navigationMenu/styles";

import Wave from "../../assets/images/bg-menu-mobile.png";

const slideInUp = keyframes`
from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }`;

export const bounceDown = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  `;

export const NavigationMenuMobileContainer = styled.div`
	z-index: 999;
	animation: ${slideInUp} linear 0.4s;
	position: fixed;
	width: 100%;
	height: 100%;
	background: #fff;
	flex-direction: column;
	display: flex;

	${MediaQueries.BIGGER_THAN_SMALL} {
		display: none;
	}
`;

export const ButtonAction = styled.button`
	background: #4b9eff;
	color: #102740;
	border: 0;
	padding: 3px 7px;
	border-radius: 3px;
	margin-top: 10px;
	font-weight: bold;
	font-size: 13px;
`;

export const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	border: 0;
	background: transparent;
	padding: 4px;

	svg {
		width: 13px;
		height: 13px;
	}
`;

export const NavigationMenuMobileHeader = styled.div`
	background: #561d5d;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 20px 15px;
	position: relative;

	h2 {
		color: #fff;
		font-size: 16px;
	}
`;

export const NavigationMenuMobileContent = styled.div`
	flex: 1;
	width: 100vw;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	background: repeating-linear-gradient(
			0deg,
			transparent,
			transparent 10px,
			#c8cbd9 10px,
			#c8cbd9 20px
		),
		/* on "bottom" */ linear-gradient(to bottom, transparent, transparent);
	background-size: 1px;
	background-repeat: repeat-y;
	background-position: left 26px center;

	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	/* width: 20px; */

	padding: 2rem 20px;

	${NavigationBullWrapp} {
		opacity: 1 !important;
		margin: 1rem 0;
	}

	${NavigationBull} {
		width: 13px;
		height: 13px;
	}

	${NavgationAction} {
		left: -5px;
		align-items: flex-start;
		padding-left: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;

		padding-top: 14px;
		padding-bottom: 14px;
		top: -15px;

		strong {
			margin-bottom: 0;
		}

		span {
			margin-left: 10px;
		}

		opacity: 1 !important;

		box-shadow: none;
		background: transparent;

		${NavgationAction} {
			opacity: 1 !important;
		}
	}
`;

export const NavigationWave = styled.div`
	width: 100vw;
	background: url(${Wave});
	height: 39px;
	background-position: bottom;

	background-size: cover;

	position: absolute;
	left: 0;
	bottom: -33px;
	z-index: 7;
`;
