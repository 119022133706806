import styled from "styled-components";
import Slick from "react-slick";
import wave from "../../../../assets/images/home/wave.png";
import { MediaQueries } from "../../../../assets/mediaQueries";

export const ContainerSchedule = styled.div`
	background: #fff;
	flex: 1;
	position: relative;
	padding-bottom: 40px;

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		margin-top: -100px;
		padding-top: 50px;
	}
`;

export const ScheduleWave = styled.div`
	background: url(${wave}) center top no-repeat;
	background-size: cover;

	${MediaQueries.SMALL} {
		height: 80px;
	}

	${MediaQueries.BIGGER_THAN_SMALL} {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}

	${MediaQueries.TABLET_PORTRAIT} {
		height: 140px;
	}

	${MediaQueries.TABLET_LANDSCAPE} {
		height: 140px;
	}

	${MediaQueries.NOTEBOOK} {
		height: 200px;
	}

	${MediaQueries.DESKTOP} {
		height: 200px;
	}
`;

export const TitleSchedule = styled.h2`
	font-family: Nunito;
	font-weight: bold;
	font-size: 24px;
	line-height: 36px;
	color: #25272d;
	text-align: center;

	${MediaQueries.BIGGER_THAN_SMALL} {
		padding-top: 80px;
	}
`;

export const ContainerSlider = styled.div`
	position: relative;

	${MediaQueries.SMALL} {
		width: 100%;
		margin-right: 15px;
		margin-top: 0px;
	}
`;

export const SliderSchedule = styled(Slick)`
	z-index: 1 !important;

	.slick-track {
		margin: 0px;
	}
`;

export const TimeLine = styled.span`
	position: absolute;
	width: 98%;
	bottom: 47px;
	border: 1px solid #c8cbd9;
`;
