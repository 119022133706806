import Thumb1 from "../img/humanas/chaves.png";
import Thumb2 from "../img/humanas/dayana.png";
import Thumb3 from "../img/humanas/feitosa.png";
import Thumb4 from "../img/humanas/felipe.png";
import Thumb5 from "../img/humanas/lameirao.png";
import Thumb6 from "../img/humanas/leandro-almeida.png";
import Thumb7 from "../img/humanas/Leandro-Bolivar.png";
import Thumb8 from "../img/humanas/leandro-vieira.png";
import Thumb9 from "../img/humanas/otto.png";
import Thumb10 from "../img/humanas/paulo-andrade.png";
import Thumb11 from "../img/humanas/romulo.png";
import Thumb12 from "../img/humanas/thiago-alexandre.png";

const professoresHumanas = [
	Thumb1,
	Thumb2,
	Thumb3,
	Thumb4,
	Thumb5,
	Thumb6,
	Thumb7,
	Thumb8,
	Thumb9,
	Thumb10,
	Thumb11,
	Thumb12
];

export default professoresHumanas;
