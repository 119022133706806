import React from "react";

// Assets
import MaratonaIntensivaLogo from "./img/logo-maratona-intensiva.png";
import ArrowCircle from "./img/arrow-circle.png";
import Circles from "./img/circles.png";
import Lines from "./img/lines.png";
import Squares from "./img/squares.png";
import Romulo from "./img/romulo.png";

import Container from "../../container";
import {
	MaratonaIntensivaContainer,
	MaratonaIntensivaLogoWrapper,
	MaratonaIntensivaTitle,
	MaratonaIntensivaList,
	Vector1,
	Vector2,
	Vector3,
	Vector4,
	MaratonaIntensivaFooter,
	MaratonaThumb,
	MaratonaIntensivaPricing,
	MaratonaIntensivaPricingWrapper,
	MaratonaActions,
	Button,
	MaratonaObs,
	MataronaIntensivaText
} from "./styles";
import { Row, Col } from "../../grid";
import useIsMobile from "../../../hooks/use-is-mobile";

const MaratonaIntensiva = () => {
	const isMobile = useIsMobile();

	return (
		<MaratonaIntensivaContainer>
			<Container>
				<Row middle="sm">
					<Col xs={12} sm={4}>
						<MaratonaIntensivaLogoWrapper
							src={MaratonaIntensivaLogo}
							alt="Maratona Redação Enem Intesivo"
						/>
					</Col>
					<Col xs={12} sm={8}>
						<MaratonaIntensivaTitle>
							Prepare-se para a Redação em 24 horas
						</MaratonaIntensivaTitle>
						{isMobile && (
							<MataronaIntensivaText>
								Assine agora o curso que reúne teoria e prática
								com o melhor professor de Redação da internet.
								<br />
								<br />
								Maratone de qualquer lugar todo o conteúdo que
								vai fazer você mandar muito bem no Enem.
								<br />
								<br />
								Em 2018, <em>55 pessoas</em> tiraram{" "}
								<strong>NOTA 1000</strong> na redação e DUAS
								dessas pessoas eram{" "}
								<strong>nossos alunos</strong>.
							</MataronaIntensivaText>
						)}
						{!isMobile && (
							<MataronaIntensivaText>
								Assine agora o curso que reúne teoria e prática
								com o melhor professor de Redação da internet.
								Maratone de qualquer lugar todo o conteúdo que
								vai fazer você mandar muito bem no Enem. Em
								2018, 55 pessoas tiraram nota 1000 na redação e
								duas dessas pessoas eram nossos alunos.
							</MataronaIntensivaText>
						)}
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={8} mdOffset={3}>
						<MaratonaIntensivaList>
							<li>Videoaulas exclusivas</li>
							<li>Material didático exclusivo</li>
							<li>Temas inéditos de redação</li>
							<li>Podcasts para ouvir de qualquer lugar</li>
							<li>Correção comentada</li>
						</MaratonaIntensivaList>
					</Col>
				</Row>
			</Container>
			<Row>
				<Col xs={12}>
					<MaratonaIntensivaFooter>
						<Container>
							<Vector1>
								<img
									alt="Maratona Redação ENEM Intensivo"
									src={Squares}
								/>
							</Vector1>
							<Vector2>
								<img
									alt="Maratona Redação ENEM Intensivo"
									src={ArrowCircle}
								/>
							</Vector2>
							<MaratonaThumb>
								<img
									alt="Maratona Redação ENEM Intensivo"
									src={Romulo}
								/>
							</MaratonaThumb>
							<MaratonaIntensivaPricing>
								<MaratonaIntensivaPricingWrapper>
									<strong>Tudo isso por</strong>
									<p>
										<span>10x</span>
										<strong>R$ 9,90</strong>
									</p>
								</MaratonaIntensivaPricingWrapper>
								<MaratonaActions>
									<Button href="#" title="Comprar">
										Comprar
									</Button>
									<Button
										outline={true}
										href="#"
										title="Quero testar"
									>
										Quero testar
									</Button>
								</MaratonaActions>
								<MaratonaObs
									href="/#"
									title="Aluno Proenem tem desconto"
									target="_blank"
								>
									Aluno ProEnem tem desconto.{" "}
									<strong>Clique aqui</strong>
								</MaratonaObs>
							</MaratonaIntensivaPricing>
						</Container>
						<Vector3>
							<img
								alt="Maratona Redação ENEM Intensivo"
								src={Lines}
							/>
						</Vector3>
						<Vector4>
							<img
								alt="Maratona Redação ENEM Intensivo"
								src={Circles}
							/>
						</Vector4>
					</MaratonaIntensivaFooter>
				</Col>
			</Row>
		</MaratonaIntensivaContainer>
	);
};

export default MaratonaIntensiva;
