import styled, { css, keyframes } from "styled-components";
import { MediaQueries } from "../../assets/mediaQueries";

const fadeInRight = keyframes`from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }`;

export const NavigationContainer = styled.div`
	${MediaQueries.SMALL} {
		display: none;
	}

	animation: ${fadeInRight} 0.3s linear;

	/* position: fixed;
	top: 0;
	right: 40px; */
	height: 80vh;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	justify-content: space-between;
	padding: 10% 0;
	z-index: 100;

	background: repeating-linear-gradient(
			0deg,
			transparent,
			transparent 10px,
			#c8cbd9 10px,
			#c8cbd9 20px
		),
		/* on "bottom" */ linear-gradient(to bottom, transparent, transparent);
	background-size: 2px;
	background-repeat: repeat-y;
	background-position: center;
`;

export const NavigationBull = styled.div`
	background: #794a7d;
	width: 16px;
	height: 16px;
	border-radius: 100%;
	position: relative;
	z-index: 1;
`;

export const NavgationAction = styled.div`
	opacity: 0;
	background: #fff;
	padding: 8px;
	display: flex;
	flex-direction: column;
	position: absolute;
	right: -5px;
	top: -5px;
	border-radius: 5px;
	min-width: 140px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

	transition: opacity 0.3s;

	strong {
		margin-bottom: 6px;
		color: #25272d;
		font-size: 13px;
	}

	span {
		color: #7c8195;
		font-size: 12px;
	}
`;

export const NavigationBullWrapp = styled.div<{ active?: boolean }>`
	position: relative;
	cursor: pointer;
	&:hover {
		${NavgationAction} {
			opacity: 1;
		}
	}

	${({ active }) =>
		active &&
		css`
			${NavigationBull} {
				background: #74b4fe;
			}

			${NavgationAction} {
				opacity: 1;
			}
		`}
`;

export const Navigation = styled.div`
	position: fixed;
	top: 0;
	height: 100vh;
	padding: 10% 0;
	right: 40px;

	display: flex;
	align-items: center;
	z-index: 998;
`;
