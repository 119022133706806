import styled from "styled-components";
import { MediaQueries } from "../../../../assets/mediaQueries";

export interface IQuestionCardProps {
	answered?: boolean;
}
export const QuestionCard = styled.article<IQuestionCardProps>`
	background: #fff;
	width: 100%;
	min-height: 300px;
	border-radius: 4px;
	margin-top: 20px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 40px;
	position: relative;

	${MediaQueries.BIGGER_THAN_SMALL} {
		width: 85%;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		width: 90%;
	}

	${({ answered }) => !answered && `padding-bottom: 40px;`}

	h3 {
		font-family: "Nunito";
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		margin: 20px;
	}

	ol {
		list-style-type: none;
		li {
			color: #7c8195;
			margin-left: 20px;
			margin-right: 20px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			font-weight: 400;
			font-family: "Nunito Sans";
			transition: all 0.15s;
			cursor: pointer;

			&.checked {
				color: #3a3e47;
				font-weight: 600;
			}
		}
	}

	button {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		border: none;
		background: #4b1451;
		border-radius: 4px;
		height: 40px;
		color: #ffffff;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		margin-top: 10px;
		transition: all 0.15s;
		cursor: pointer;

		&[disabled] {
			background: #f0f1f7;
			color: #9c9fb0;
		}
	}
`;

export const ProgressDetails = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

export const OptionDetails = styled.div`
	width: 100%;
`;
