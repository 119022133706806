import React from "react";
import {
	Section,
	Logo,
	Title,
	Text,
	Button,
	Link,
	Wrapper,
	Teachers
} from "./styles";

import Container from "../../container";
import logo from "../../../assets/img/gabarito-humanas/logo.png";
import useIsMobile from "../../../hooks/use-is-mobile";
import { Row, Col } from "../../grid";
import teachers from "../../../assets/img/gabarito-humanas/professores.png";

const GabaritoHumanas = () => {
	const isMobile = useIsMobile();

	return (
		<Section>
			<Container>
				<Row middle="sm">
					<Col xs={12} sm={6}>
						<Logo src={logo} />
						<Title>
							Simule sua nota e veja as chances de passar
						</Title>
						<Text>
							Aqui no Proenem você poderá simular a sua nota de
							acordo com o TRI e também poderá acompanhar, ao vivo
							no YouTube, os comentários da equipe que{" "}
							<strong>acertou 100%</strong> das questões do{" "}
							<strong>Enem 2018</strong>.
							{isMobile && (
								<>
									<br />
									<br />
									Plataforma perfeita faz assim!
								</>
							)}
							{!isMobile && <> Plataforma perfeita faz assim!</>}
						</Text>
						<Wrapper>
							<Button>Assistir</Button>
							<Link
								href="https://gabaritos.proenem.com.br"
								target="_blank"
							>
								Simular nota
							</Link>
						</Wrapper>
					</Col>
					<Col sm={5}>
						<Teachers src={teachers} />
					</Col>
				</Row>
			</Container>
		</Section>
	);
};

export default GabaritoHumanas;
