import React from "react";

import {
	NavigationContainer,
	NavigationBull,
	NavigationBullWrapp,
	NavgationAction,
	Navigation
} from "./styles";

interface INavigationItemProps {
	item: any;
	handleClick(): void;
	active: boolean;
}

interface INavigationMenuProps {
	handleClick(e: any): void;
	pages: any[];
}

export const NavigationItem = ({
	item,
	handleClick,
	active
}: INavigationItemProps) => {
	return (
		<NavigationBullWrapp active={active} onClick={() => handleClick()}>
			<NavigationBull />
			<NavgationAction>
				<strong>{item.date}</strong>
				<span>{item.name}</span>
			</NavgationAction>
		</NavigationBullWrapp>
	);
};

const NavigationMenu = ({ handleClick, pages }: INavigationMenuProps) => {
	return (
		<Navigation>
			<NavigationContainer>
				{pages.map(item => (
					<NavigationItem
						active={item.active}
						item={item}
						key={item.slug}
						handleClick={() => handleClick(item)}
					/>
				))}
			</NavigationContainer>
		</Navigation>
	);
};

export default NavigationMenu;
