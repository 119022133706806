import React from "react";

// import { Grid } from "../../grid";
// import { Section } from "./styles";

import Header from "./header";
import Headline from "./headline";
import Schedule from "./schedule";
import { Section } from "./styles";

interface IProps {
	handleClick(): void;
	handleScroll(page: any): void;
}

const Home = ({ handleClick, handleScroll }: IProps) => {
	return (
		<>
			<Header
				handleClick={handleClick}
				handleClickAction={handleScroll}
			/>
			<Section>
				<Headline />
				<Schedule handleScroll={handleScroll} />
			</Section>
		</>
	);
};

export default Home;
