import React from "react";

// Assets
import {
	Section,
	Container,
	AulaoDeVesperaLogo,
	ImageSlider,
	AulaoDetails,
	TitleSlider,
	InformationsSlider,
	ArrowTeacher,
	AulaoDeVesperaSliderContainer,
	AulaoDeVesperaSlider,
	Apresentadora
} from "./styles";
import Logo from "../../../assets/images/AULAO-DE-VESPERA.png";

import Arrows from "./arrows";

// Helpers
import AulaoLegend from "../../aulao/legend";
import AulaoTitle from "../../aulao/title";
import AulaoButton from "../../aulao/button";
import { Row, Col } from "../../grid";
import useIsMobile from "../../../hooks/use-is-mobile";

export interface IAulaoDeVesperaProps {
	title: string;
	legend: any;
	handleClick(): void;
	image: any;
	teachers: any;
	isHumans: boolean;
	gutterTop?: boolean;
}

const AulaoDeVespera = ({
	title,
	legend,
	handleClick,
	image,
	teachers,
	isHumans,
	gutterTop
}: IAulaoDeVesperaProps) => {
	const isMobile = useIsMobile();
	const renderTeachers = () => {
		const mappedTeachers = teachers.map((teacher: string) => (
			<ImageSlider src={teacher} />
		));

		return mappedTeachers;
	};

	return (
		<Section>
			<Container style={{ paddingTop: gutterTop ? "40px" : undefined }}>
				<Row middle="sm" reverse={!isMobile}>
					<Col xs={12} sm={5}>
						<AulaoDeVesperaLogo src={Logo} alt="Aulão de Véspera" />
					</Col>
					<Col xs={12} sm={6} md={7}>
						<AulaoDetails>
							<AulaoTitle>{title}</AulaoTitle>
							<AulaoLegend>{legend}</AulaoLegend>
							<AulaoButton onClick={() => handleClick()}>
								Saiba Mais
							</AulaoButton>
						</AulaoDetails>
					</Col>
				</Row>
				<Row middle="md">
					<Col xs={12} sm={7} md={6}>
						<InformationsSlider>
							<TitleSlider isHumans={isHumans}>{`Só professor ${
								isHumans ? "sinistro" : "TOP"
							}`}</TitleSlider>
							<ArrowTeacher />
						</InformationsSlider>
						<AulaoDeVesperaSliderContainer>
							<AulaoDeVesperaSlider
								infinite={false}
								arrows
								dots={true}
								slidesToShow={3}
								slidesToScroll={1}
								nextArrow={<Arrows />}
								prevArrow={<Arrows left />}
								responsive={[
									{
										breakpoint: 420,
										settings: {
											slidesToShow: 2,
											slidesToScroll: 1
										}
									}
								]}
							>
								{renderTeachers()}
							</AulaoDeVesperaSlider>
						</AulaoDeVesperaSliderContainer>
					</Col>
					<Col xs={12} sm={4} md={6}>
						<Apresentadora
							src={image}
							alt="Apresentadora do 1º Aulão de Véspera"
						/>
					</Col>
				</Row>
				<Row></Row>
			</Container>
		</Section>
	);
};

export default AulaoDeVespera;
