import styled, { css } from "styled-components";
import container from "../container";

import btnUp from "../../assets/img/footer/btn-up.png";
// import icoMenu from "../../assets/img/footer/ico-down.png";

import iconFacebook from "../../assets/img/footer/facebook.png";
import iconTwitter from "../../assets/img/footer/twitter.png";
import iconInstagram from "../../assets/img/footer/instagram.png";
import iconYoutube from "../../assets/img/footer/youtube.png";
import { MediaQueries } from "../../assets/mediaQueries";

import Container from "../container";

export const Section = styled.footer`
	background: #fafafc;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
	padding: 32px 0 30px 80px;

	${MediaQueries.SMALL} {
		padding: 30px 20px;

		${container} {
			position: relative;
			padding-top: 130px;
		}
	}

	${MediaQueries.TABLET} {
		padding: 30px 20px;
	}

	${Container} {
		position: relative;
	}
`;

export const ButtonNavTop = styled.button`
	cursor: pointer;
	position: absolute;
	top: -60px;
	right: 0;
	background: url(${btnUp}) center no-repeat;
	width: 64px;
	height: 64px;
	border: none;
`;

export const Wrapper = styled.div`
	flex: 1;
	padding: 0 1rem;

	${MediaQueries.SMALL} {
		padding: 0 0 !important;
	}
`;

export const Title = styled.strong`
	display: flex;
	justify-content: space-between;

	font-family: Nunito;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	color: #505462;
	border-bottom: 1px solid #f0f1f7;
	padding: 0 0 15px 3px;
	margin-bottom: 15px;
`;

export const TitleNews = styled.h6`
	font-family: Nunito;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #666b7b;
	margin-bottom: 12px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		width: 220px;
	}
`;

export const LinksColumn = styled.div`
	${MediaQueries.BIGGER_THAN_SMALL} {
		column-count: 2;
		column-gap: 40px;
	}
`;

export const Link = styled.a`
	font-family: Nunito Sans;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.01em;
	color: #666b7b;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

export const Nav = styled.nav`
	${MediaQueries.BIGGER_THAN_SMALL} {
		display: flex;
	}

	${Link} {
		display: block;
		margin-bottom: 4px;
	}
`;

export const Links = styled.div<{ isOpen?: boolean }>`
	${MediaQueries.SMALL} {
		/* display: ${props => (props.isOpen ? "block" : "none")}; */
		padding: 0 15px;
		margin-bottom: 20px;

		${Link} {
			line-height: 22px !important;
			margin-bottom: 5px;
		}
	}
`;

export const WrapperMobile = styled.div`
	${MediaQueries.SMALL} {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
`;

export const WrapperMobileApp = styled.div`
	${MediaQueries.SMALL} {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 15px;
		${TitleNews} {
		}
	}
`;

export const LinksColumnMobile = styled.div`
	${MediaQueries.SMALL} {
		column-count: 2;
		column-gap: 15px;

		${Link} {
			display: block;
			line-height: 22px !important;
		}
	}
`;

export const Form = styled.form`
	${MediaQueries.SMALL} {
		display: flex;
	}
	${MediaQueries.BIGGER_THAN_SMALL} {
		width: 220px;
		margin-bottom: 30px;
	}
`;

export const Field = styled.input`
	width: 100%;
	background: #fff;
	padding: 8px 10px;
	border: 1px solid #f0f1f7;
	border-radius: 4px;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #222;
	margin-right: 5px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin: 0 0 10px 0;
	}

	::placeholder {
		color: #c8cbd9;
	}
`;

export const Button = styled.button`
	background: #4b1451;
	border-radius: 4px;
	font-family: Nunito;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	letter-spacing: 0.005em;
	color: #ffffff;
	border: none;
	padding: 6px 15px;
	cursor: pointer;
	flex-shrink: 0;
`;

export const NavInstitucional = styled.nav`
	margin-top: 10px;
	position: relative;
	border-top: 1px solid #f0f1f7;
	display: flex;
	align-items: center;
	padding-top: 20px;
	flex-direction: column;

	${Link} {
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.01em;
		color: #9c9fb0;
		margin-right: 16px;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		justify-content: space-between;
		flex-direction: row;
	}
`;

export const SocialLinks = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 20px;

	a {
		display: block;
		width: 20px;
		height: 20px;
		margin-right: 25px;
	}

	${MediaQueries.BIGGER_THAN_MEDIUM} {
		padding-top: 0px;
	}
`;
export const Facebook = styled.a`
	background: url(${iconFacebook}) center no-repeat;
`;
export const Instagram = styled.a`
	background: url(${iconInstagram}) center no-repeat;
`;
export const Youtube = styled.a`
	background: url(${iconYoutube}) center no-repeat;
	width: 28px !important;
	height: 28px !important;
`;
export const Twitter = styled.a`
	background: url(${iconTwitter}) center no-repeat;
	width: 24px !important;
`;

export const AccordionIcon = styled.svg<{ active: boolean }>`
	${({ active }) =>
		active &&
		css`
			transform: rotate(180deg);
		`};
`;
