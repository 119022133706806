import React from "react";

// Assets
import Versus from "../img/versus.png";
import { BattleVersus, BattlePlayerThumb } from "./styles";

// Helpers
import professoresExatas from "./exatas";
import professoresHumanas from "./humanas";
import { Col, Row } from "../../../grid";
import { Guest } from "../styles";
import Thati from "../img/thati_lopes.png";

const Battle = () => {
	return (
		<Row middle="sm">
			<Col xs={12} sm={7} md={7}>
				<Row
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Col xs={12} sm={3} md={5}>
						<BattlePlayerThumb
							alt="Game Show Proenem Team Humanas"
							src={
								professoresHumanas[
									Math.floor(Math.random() * (10 - 0 + 1) + 0)
								]
							}
						/>
					</Col>
					<Col xs={12} sm={1} md={2}>
						<BattleVersus alt="Game Show Proenem" src={Versus} />
					</Col>
					<Col xs={12} sm={3} md={5}>
						<BattlePlayerThumb
							alt="Game Show Proenem Team Exatas"
							src={
								professoresExatas[
									Math.floor(Math.random() * (10 - 0 + 1) + 0)
								]
							}
						/>
					</Col>
				</Row>
			</Col>
			<Col xs={12} sm={5} md={5}>
				<Guest alt="Thati Lopes" src={Thati} />
			</Col>
		</Row>
	);
};

export default Battle;
