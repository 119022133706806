import styled from "styled-components";
import { Section } from "../styles";
import { MediaQueries } from "../../../assets/mediaQueries";

export const GameShowContainer = styled(Section)`
	background: #fff;

	${MediaQueries.BIGGER_THAN_SMALL} {
		min-height: 100vh !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: -2rem;
	}
`;

export const GameShowIntro = styled.div`
	margin-bottom: 50px;
	position: relative;
	width: 480px;

	img {
		display: none;
	}
`;

export const GameShowRow = styled.div`
	position: relative;
`;

export const GameShowTitle = styled.h2`
	color: #25272d;
	font-family: "Nunito";
	font-weight: 800;
	font-size: 21px;
	line-height: 1.25;
	margin-bottom: 22px;
	text-align: center;
	margin-top: 30px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-bottom: 30px;
		font-size: 26px;
		text-align: left;
		padding-left: 30px;
		padding-right: 30px;
	}
`;

export const GameShowText = styled.p`
	color: #666b7b;
	font-family: "Nunito Sans";
	font-size: 16px;
	line-height: 22px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	margin-bottom: 30px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		text-align: left;
		padding-left: 30px;
		padding-right: 30px;
	}
`;

export const GameShowLogoContainer = styled.img`
	width: 100%;
	max-width: 300px;
	display: block;
	margin: 0 auto;
	margin-top: 30px;

	${MediaQueries.BIGGER_THAN_SMALL} {
		margin-top: 0px;
	}
`;

export const GameShowLogoArrow = styled.img`
	display: none;

	${MediaQueries.BIGGER_THAN_SMALL} {
		width: 100%;
		max-width: 114px;
		display: block;
	}
`;

export const Guest = styled.img`
	width: 100%;
	/* max-width: 250px; */
	display: block;
	margin: 0 auto;
	position: relative;
	left: 20px;
	max-width: 100%;

	margin-bottom: 2rem;

	${MediaQueries.SMALL} {
		left: 0;
		position: unset !important;
	}
`;
